import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Link, useLocation, useHistory } from "react-router-dom"
import Dropzone from "react-dropzone"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import * as yup from "yup"
import { useFormik } from "formik"
import { toast } from "react-toastify"
import axios from "axios"
import { useSelector, useDispatch } from "react-redux"
import "./custom.css"
import { getUsersById as onGetUsersById } from "store/users/actions"

const uboStructure = {
  detailtype: "ubo",
  firstname: "",
  lastname: "",
  countrycode: "",
  contact: "",
  document: [
    {
      documenttype: "Identity Proof",
      documentsubtype: "",
      documenturl: "",
      expirydate: "",
      documentnumber: "",
      filetype: "",
      fileName: "",
      status: "Approved",
    },
    {
      documenttype: "Residence Proof",
      documenturl: "",
      documentsubtype: "",
      expirydate: "",
      fileType: "",
      fileName: "",
      status: "Approved",
      country: "",
      provience: "",
      zipcode: "",
      city: "",
      street: "",
      housenumber: "",
      region: "",
    },
    {
      documenttype: "Share holding Registry",
      documenturl: "",
      fileType: "",
      fileName: "",
      status: "Approved",
    },
  ],
}

const lrStructure = {
  detailtype: "lr",
  firstname: "",
  lastname: "",
  countrycode: "",
  contact: "",
  document: [
    {
      documenttype: "Identity Proof",
      documentsubtype: "",
      documenturl: "",
      expirydate: "",
      documentnumber: "",
      filetype: "",
      fileName: "",
      status: "Approved",
    },
    {
      documenturl: "",
      documenttype: "Residence Proof",
      documentsubtype: "",
      expirydate: "",
      fileType: "",
      fileName: "",
      status: "Approved",
      country: "",
      provience: "",
      zipcode: "",
      city: "",
      street: "",
      housenumber: "",
      region: "",
    },
  ],
}

const companyStructure = {
  companyname: "",
  companyincorporatedate: "",
  taxnumber: "",
  numberofubo: "",
  numberoflr: "",
  detailtype: "company",
  lang_id: 1,
  document: [
    {
      documenttype: "verification",
      documentsubtype: "Trade register",
      documenturl: "",
      fileType: "",
      fileName: "",
      status: "Approved",
    },
    {
      documenttype: "verification",
      documentsubtype: "Authorized signatories of the company",
      documenturl: "",
      fileType: "",
      fileName: "",
      status: "Approved",
    },
    {
      documenttype: "verification",
      documentsubtype: "Articles of Association",
      documenturl: "",
      fileType: "",
      fileName: "",
      status: "Approved",
    },
    {
      documenttype: "verification",
      documentsubtype: "Financial Statements",
      documenturl: "",
      fileType: "",
      fileName: "",
      status: "Approved",
    },
    {
      documenttype: "verification",
      documentsubtype: "Financial Statements1",
      documenturl: "",
      fileType: "",
      fileName: "",
      status: "Approved",
    },
  ],
}

const pointOfContact = {
  firstname: "",
  lastname: "",
  email: "",
  contact: "",
  countrycode: "",
  detailtype: "pointofcontact",
  lang_id: 1,
}

const payload = {
  email: "",
  contact: null,
  countrycode: null,
  lang_id: 1,
  role: "legalrepresent",
  type: "user",
  access_group: "intermediate",
  isVerified: false,
  status: "approved",
  islr: true,
  userdetail: {
    lang_id: 1,
    linkDevice: "1",
    img: "",
    userlrdetail: [],
  },
}

const AddLr = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const searchParams = new URLSearchParams(location.search)
  const userId = searchParams.get("id")
  const [isEdit, setIsEdit] = useState(false)
  const { usersById } = useSelector(state => ({
    usersById: state.Users.usersById,
    error: state.Users.error,
  }))

  //   get user details
  useEffect(() => {
    if (userId !== null && userId !== undefined) {
      //call api to get company data;
      dispatch(onGetUsersById(userId))
      setIsEdit(true)
    }
  }, [userId])

  const [tradeRegisterPreview, setTradeRegisterPreview] = useState({
    fileName: "",
    fileType: "",
    filePreviewObj: "",
    uploadedFileData: "",
  })
  const [authorizedSignatoriesPreview, setAuthorizedSignatoriesPreview] =
    useState({
      fileName: "",
      fileType: "",
      filePreviewObj: "",
      uploadedFileData: "",
    })
  const [articlesOfAssociationPreview, setArticlesOfAssociationPreview] =
    useState({
      fileName: "",
      fileType: "",
      filePreviewObj: "",
      uploadedFileData: "",
    })
  const [financialStatementsPreview, setFinancialStatementsPreview] = useState({
    fileName: "",
    fileType: "",
    filePreviewObj: "",
    uploadedFileData: "",
  })
  const [financialStatements1Preview, setFinancialStatements1Preview] =
    useState({
      fileName: "",
      fileType: "",
      filePreviewObj: "",
      uploadedFileData: "",
    })
  const [uboPassportPreview_1, setUboPassportPreview_1] = useState({
    fileName: "",
    fileType: "",
    filePreviewObj: "",
    uploadedFileData: "",
  })
  const [uboPassportPreview_2, setUboPassportPreview_2] = useState({
    fileName: "",
    fileType: "",
    filePreviewObj: "",
    uploadedFileData: "",
  })
  const [uboPassportPreview_3, setUboPassportPreview_3] = useState({
    fileName: "",
    fileType: "",
    filePreviewObj: "",
    uploadedFileData: "",
  })
  const [uboIdentityCardPreview_1, setUboIdentityCardPreview_1] = useState({
    fileName: "",
    fileType: "",
    filePreviewObj: "",
    uploadedFileData: "",
  })
  const [uboIdentityCardPreview_2, setUboIdentityCardPreview_2] = useState({
    fileName: "",
    fileType: "",
    filePreviewObj: "",
    uploadedFileData: "",
  })
  const [uboIdentityCardPreview_3, setUboIdentityCardPreview_3] = useState({
    fileName: "",
    fileType: "",
    filePreviewObj: "",
    uploadedFileData: "",
  })
  const [shareholdingRegisterPreview_1, setShareholdingRegisterPreview_1] =
    useState({
      fileName: "",
      fileType: "",
      filePreviewObj: "",
      uploadedFileData: "",
    })
  const [shareholdingRegisterPreview_2, setShareholdingRegisterPreview_2] =
    useState({
      fileName: "",
      fileType: "",
      filePreviewObj: "",
      uploadedFileData: "",
    })
  const [shareholdingRegisterPreview_3, setShareholdingRegisterPreview_3] =
    useState({
      fileName: "",
      fileType: "",
      filePreviewObj: "",
      uploadedFileData: "",
    })
  const [uboResidenceProofPreview_1, setUboResidenceProofPreview_1] = useState({
    fileName: "",
    fileType: "",
    filePreviewObj: "",
    uploadedFileData: "",
  })
  const [uboResidenceProofPreview_2, setUboResidenceProofPreview_2] = useState({
    fileName: "",
    fileType: "",
    filePreviewObj: "",
    uploadedFileData: "",
  })
  const [uboResidenceProofPreview_3, setUboResidenceProofPreview_3] = useState({
    fileName: "",
    fileType: "",
    filePreviewObj: "",
    uploadedFileData: "",
  })
  // LR States
  const [lrPassportPreview_1, setLrPassportPreview_1] = useState({
    fileName: "",
    fileType: "",
    filePreviewObj: "",
    uploadedFileData: "",
  })
  const [lrPassportPreview_2, setLrPassportPreview_2] = useState({
    fileName: "",
    fileType: "",
    filePreviewObj: "",
    uploadedFileData: "",
  })
  const [lrPassportPreview_3, setLrPassportPreview_3] = useState({
    fileName: "",
    fileType: "",
    filePreviewObj: "",
    uploadedFileData: "",
  })
  const [lrIdentityCardPreview_1, setLrIdentityCardPreview_1] = useState({
    fileName: "",
    fileType: "",
    filePreviewObj: "",
    uploadedFileData: "",
  })
  const [lrIdentityCardPreview_2, setLrIdentityCardPreview_2] = useState({
    fileName: "",
    fileType: "",
    filePreviewObj: "",
    uploadedFileData: "",
  })
  const [lrIdentityCardPreview_3, setLrIdentityCardPreview_3] = useState({
    fileName: "",
    fileType: "",
    filePreviewObj: "",
    uploadedFileData: "",
  })
  const [lrResidenceProofPreview_1, setLrResidenceProofPreview_1] = useState({
    fileName: "",
    fileType: "",
    filePreviewObj: "",
    uploadedFileData: "",
  })
  const [lrResidenceProofPreview_2, setLrResidenceProofPreview_2] = useState({
    fileName: "",
    fileType: "",
    filePreviewObj: "",
    uploadedFileData: "",
  })
  const [lrResidenceProofPreview_3, setLrResidenceProofPreview_3] = useState({
    fileName: "",
    fileType: "",
    filePreviewObj: "",
    uploadedFileData: "",
  })

  const [uboIdProofs, setUboIdProofs] = useState([
    "passport",
    "passport",
    "passport",
  ])
  const [lrIdProofs, setLrIdProofs] = useState([
    "passport",
    "passport",
    "passport",
  ])
  const [uboCount, setUboCount] = useState(1)
  const [legalRepCount, setLegalRepCount] = useState(1)
  const [langID, setLangID] = useState(1)

  const documentTypeList = [
    { value: "Gas bill", label: "Gas bill" },
    { value: "TV bill", label: "TV bill" },
    { value: "Water bill", label: "Water bill" },
    { value: "Electricity bill", label: "Electricity bill" },
    { value: "Internet bill", label: "Internet bill" },
    { value: "Landline bill", label: "Landline bill" },
    {
      value: "Tax invoice or Definitive tax assessment",
      label: "Tax invoice or Definitive tax assessment",
    },
    {
      value: "Residence certificate issued by your municipality",
      label: "Residence certificate issued by your municipality",
    },
  ]

  const documentTypeListItalian = [
    { value: "Bolletta del gas", label: "Bolletta del gas" },
    {
      value: "Fattura del canone televisivo",
      label: "Fattura del canone televisivo",
    },
    { value: "Bolletta dell'acqua", label: "Bolletta dell'acqua" },
    { value: "Bolletta dell'elettricità", label: "Bolletta dell'elettricità" },
    { value: "Bolletta Internet", label: "Bolletta Internet" },
    {
      value: "Bolletta della linea fissa",
      label: "Bolletta della linea fissa",
    },
    {
      value: "Fattura fiscale o Accertamento fiscale definitivo",
      label: "Fattura fiscale o Accertamento fiscale definitivo",
    },
    {
      value: "Certificato di residenza rilasciato dal comune di residenza",
      label: "Certificato di residenza rilasciato dal comune di residenza",
    },
  ]

  const residenceCountry = [
    { value: "BE", label: "Belgium" },
    { value: "BG", label: "Bulgaria" },
    { value: "CZ", label: "Czech Republic" },
    { value: "DK", label: "Denmark" },
    { value: "DE", label: "Germany" },
    { value: "EE", label: "Estonia" },
    { value: "IE", label: "Ireland" },
    { value: "GR", label: "Greece" },
    { value: "ES", label: "Spain" },
    { value: "FR", label: "France" },
    { value: "HR", label: "Croatia" },
    { value: "IT", label: "Italy" },
    { value: "CY", label: "Cyprus" },
    { value: "LV", label: "Latvia" },
    { value: "LT", label: "Lithuania" },
    { value: "LU", label: "Luxembourg" },
    { value: "HU", label: "Hungary" },
    { value: "MT", label: "Malta" },
    { value: "NL", label: "Netherlands" },
    { value: "AT", label: "Austria" },
    { value: "PL", label: "Poland" },
    { value: "PT", label: "Portugal" },
    { value: "RO", label: "Romania" },
    { value: "SI", label: "Slovenia" },
    { value: "SK", label: "Slovakia" },
    { value: "FI", label: "Finland" },
    { value: "SE", label: "Sweden" },
    { value: "GB", label: "United Kingdom" },
    { value: "CH", label: "Switzerland" },
  ]

  const countryList = [
    { code: "AD", name: "Andorra", countryCode: "+376" },
    { code: "AE", name: "United Arab Emirates", countryCode: "+971" },
    { code: "AF", name: "Afghanistan", countryCode: "+93" },
    { code: "AG", name: "Antigua and Barbuda", countryCode: "+1-268" },
    { code: "AI", name: "Anguilla", countryCode: "+1-264" },
    { code: "AL", name: "Albania", countryCode: "+355" },
    { code: "AM", name: "Armenia", countryCode: "+374" },
    { code: "AO", name: "Angola", countryCode: "+244" },
    { code: "AQ", name: "Antarctica", countryCode: "+672" },
    { code: "AR", name: "Argentina", countryCode: "+54" },
    { code: "AS", name: "American Samoa", countryCode: "+1-684" },
    { code: "AT", name: "Austria", countryCode: "+43" },
    { code: "AU", name: "Australia", countryCode: "+61" },
    { code: "AW", name: "Aruba", countryCode: "+297" },
    { code: "AX", name: "Alland Islands", countryCode: "+358" },
    { code: "AZ", name: "Azerbaijan", countryCode: "+994" },
    { code: "BA", name: "Bosnia and Herzegovina", countryCode: "+387" },
    { code: "BB", name: "Barbados", countryCode: "+1-246" },
    { code: "BD", name: "Bangladesh", countryCode: "+880" },
    { code: "BE", name: "Belgium", countryCode: "+32" },
    { code: "BF", name: "Burkina Faso", countryCode: "+226" },
    { code: "BG", name: "Bulgaria", countryCode: "+359" },
    { code: "BH", name: "Bahrain", countryCode: "+973" },
    { code: "BI", name: "Burundi", countryCode: "+257" },
    { code: "BJ", name: "Benin", countryCode: "+229" },
    { code: "BL", name: "Saint Barthelemy", countryCode: "+590" },
    { code: "BM", name: "Bermuda", countryCode: "+1-441" },
    { code: "BN", name: "Brunei Darussalam", countryCode: "+673" },
    { code: "BO", name: "Bolivia", countryCode: "+591" },
    { code: "BR", name: "Brazil", countryCode: "+55" },
    { code: "BS", name: "Bahamas", countryCode: "+1-242" },
    { code: "BT", name: "Bhutan", countryCode: "+975" },
    { code: "BV", name: "Bouvet Island", countryCode: "+47" },
    { code: "BW", name: "Botswana", countryCode: "+267" },
    { code: "BY", name: "Belarus", countryCode: "+375" },
    { code: "BZ", name: "Belize", countryCode: "+501" },
    { code: "CA", name: "Canada", countryCode: "+1" },
    { code: "CC", name: "Cocos (Keeling) Islands", countryCode: "+61" },
    {
      code: "CD",
      name: "Congo, Democratic Republic of the",
      countryCode: "+243",
    },
    { code: "CF", name: "Central African Republic", countryCode: "+236" },
    { code: "CG", name: "Congo, Republic of the", countryCode: "+242" },
    { code: "CH", name: "Switzerland", countryCode: "+41" },
    { code: "CI", name: "Cote d'Ivoire", countryCode: "+225" },
    { code: "CK", name: "Cook Islands", countryCode: "+682" },
    { code: "CL", name: "Chile", countryCode: "+56" },
    { code: "CM", name: "Cameroon", countryCode: "+237" },
    { code: "CN", name: "China", countryCode: "+86" },
    { code: "CO", name: "Colombia", countryCode: "+57" },
    { code: "CR", name: "Costa Rica", countryCode: "+506" },
    { code: "CU", name: "Cuba", countryCode: "+53" },
    { code: "CV", name: "Cape Verde", countryCode: "+238" },
    { code: "CW", name: "Curacao", countryCode: "+599" },
    { code: "CX", name: "Christmas Island", countryCode: "+61" },
    { code: "CY", name: "Cyprus", countryCode: "+357" },
    { code: "CZ", name: "Czech Republic", countryCode: "+420" },
    { code: "DE", name: "Germany", countryCode: "+49" },
    { code: "DJ", name: "Djibouti", countryCode: "+253" },
    { code: "DK", name: "Denmark", countryCode: "+45" },
    { code: "DM", name: "Dominica", countryCode: "+1-767" },
    { code: "DO", name: "Dominican Republic", countryCode: "+1-809" },
    { code: "DZ", name: "Algeria", countryCode: "+213" },
    { code: "EC", name: "Ecuador", countryCode: "+593" },
    { code: "EE", name: "Estonia", countryCode: "+372" },
    { code: "EG", name: "Egypt", countryCode: "+20" },
    { code: "EH", name: "Western Sahara", countryCode: "+212" },
    { code: "ER", name: "Eritrea", countryCode: "+291" },
    { code: "ES", name: "Spain", countryCode: "+34" },
    { code: "ET", name: "Ethiopia", countryCode: "+251" },
    { code: "FI", name: "Finland", countryCode: "+358" },
    { code: "FJ", name: "Fiji", countryCode: "+679" },
    {
      code: "FK",
      name: "Falkland Islands (Malvinas)",
      countryCode: "+500",
    },
    {
      code: "FM",
      name: "Micronesia, Federated States of",
      countryCode: "+691",
    },
    { code: "FO", name: "Faroe Islands", countryCode: "+298" },
    {
      code: "FR",
      name: "France",
      countryCode: "+33",
      suggested: true,
    },
    { code: "GA", name: "Gabon", countryCode: "+241" },
    { code: "GB", name: "United Kingdom", countryCode: "+44" },
    { code: "GD", name: "Grenada", countryCode: "+1-473" },
    { code: "GE", name: "Georgia", countryCode: "+995" },
    { code: "GF", name: "French Guiana", countryCode: "+594" },
    { code: "GG", name: "Guernsey", countryCode: "+44" },
    { code: "GH", name: "Ghana", countryCode: "+233" },
    { code: "GI", name: "Gibraltar", countryCode: "+350" },
    { code: "GL", name: "Greenland", countryCode: "+299" },
    { code: "GM", name: "Gambia", countryCode: "+220" },
    { code: "GN", name: "Guinea", countryCode: "+224" },
    { code: "GP", name: "Guadeloupe", countryCode: "+590" },
    { code: "GQ", name: "Equatorial Guinea", countryCode: "+240" },
    { code: "GR", name: "Greece", countryCode: "+30" },
    {
      code: "GS",
      name: "South Georgia and the South Sandwich Islands",
      countryCode: "+500",
    },
    { code: "GT", name: "Guatemala", countryCode: "+502" },
    { code: "GU", name: "Guam", countryCode: "+1-671" },
    { code: "GW", name: "Guinea-Bissau", countryCode: "+245" },
    { code: "GY", name: "Guyana", countryCode: "+592" },
    { code: "HK", name: "Hong Kong", countryCode: "+852" },
    {
      code: "HM",
      name: "Heard Island and McDonald Islands",
      countryCode: "+672",
    },
    { code: "HN", name: "Honduras", countryCode: "+504" },
    { code: "HR", name: "Croatia", countryCode: "+385" },
    { code: "HT", name: "Haiti", countryCode: "+509" },
    { code: "HU", name: "Hungary", countryCode: "+36" },
    { code: "ID", name: "Indonesia", countryCode: "+62" },
    { code: "IE", name: "Ireland", countryCode: "+353" },
    { code: "IL", name: "Israel", countryCode: "+972" },
    { code: "IM", name: "Isle of Man", countryCode: "+44" },
    { code: "IN", name: "India", countryCode: "+91" },
    {
      code: "IO",
      name: "British Indian Ocean Territory",
      countryCode: "+246",
    },
    { code: "IQ", name: "Iraq", countryCode: "+964" },
    {
      code: "IR",
      name: "Iran, Islamic Republic of",
      countryCode: "+98",
    },
    { code: "IS", name: "Iceland", countryCode: "+354" },
    { code: "IT", name: "Italy", countryCode: "+39" },
    { code: "JE", name: "Jersey", countryCode: "+44" },
    { code: "JM", name: "Jamaica", countryCode: "+1-876" },
    { code: "JO", name: "Jordan", countryCode: "+962" },
    {
      code: "JP",
      name: "Japan",
      countryCode: "+81",
      suggested: true,
    },
    { code: "KE", name: "Kenya", countryCode: "+254" },
    { code: "KG", name: "Kyrgyzstan", countryCode: "+996" },
    { code: "KH", name: "Cambodia", countryCode: "+855" },
    { code: "KI", name: "Kiribati", countryCode: "+686" },
    { code: "KM", name: "Comoros", countryCode: "+269" },
    {
      code: "KN",
      name: "Saint Kitts and Nevis",
      countryCode: "+1-869",
    },
    {
      code: "KP",
      name: "Korea, Democratic People's Republic of",
      countryCode: "+850",
    },
    { code: "KR", name: "Korea, Republic of", countryCode: "+82" },
    { code: "KW", name: "Kuwait", countryCode: "+965" },
    { code: "KY", name: "Cayman Islands", countryCode: "+1-345" },
    { code: "KZ", name: "Kazakhstan", countryCode: "+7" },
    {
      code: "LA",
      name: "Lao People's Democratic Republic",
      countryCode: "+856",
    },
    { code: "LB", name: "Lebanon", countryCode: "+961" },
    { code: "LC", name: "Saint Lucia", countryCode: "+1-758" },
    { code: "LI", name: "Liechtenstein", countryCode: "+423" },
    { code: "LK", name: "Sri Lanka", countryCode: "+94" },
    { code: "LR", name: "Liberia", countryCode: "+231" },
    { code: "LS", name: "Lesotho", countryCode: "+266" },
    { code: "LT", name: "Lithuania", countryCode: "+370" },
    { code: "LU", name: "Luxembourg", countryCode: "+352" },
    { code: "LV", name: "Latvia", countryCode: "+371" },
    { code: "LY", name: "Libya", countryCode: "+218" },
    { code: "MA", name: "Morocco", countryCode: "+212" },
    { code: "MC", name: "Monaco", countryCode: "+377" },
    {
      code: "MD",
      name: "Moldova, Republic of",
      countryCode: "+373",
    },
    { code: "ME", name: "Montenegro", countryCode: "+382" },
    {
      code: "MF",
      name: "Saint Martin (French part)",
      countryCode: "+590",
    },
    { code: "MG", name: "Madagascar", countryCode: "+261" },
    { code: "MH", name: "Marshall Islands", countryCode: "+692" },
    {
      code: "MK",
      name: "Macedonia, the Former Yugoslav Republic of",
      countryCode: "+389",
    },
    { code: "ML", name: "Mali", countryCode: "+223" },
    { code: "MM", name: "Myanmar", countryCode: "+95" },
    { code: "MN", name: "Mongolia", countryCode: "+976" },
    { code: "MO", name: "Macao", countryCode: "+853" },
    {
      code: "MP",
      name: "Northern Mariana Islands",
      countryCode: "+1-670",
    },
    { code: "MQ", name: "Martinique", countryCode: "+596" },
    { code: "MR", name: "Mauritania", countryCode: "+222" },
    { code: "MS", name: "Montserrat", countryCode: "+1-664" },
    { code: "MT", name: "Malta", countryCode: "+356" },
    { code: "MU", name: "Mauritius", countryCode: "+230" },
    { code: "MV", name: "Maldives", countryCode: "+960" },
    { code: "MW", name: "Malawi", countryCode: "+265" },
    { code: "MX", name: "Mexico", countryCode: "+52" },
    { code: "MY", name: "Malaysia", countryCode: "+60" },
    { code: "MZ", name: "Mozambique", countryCode: "+258" },
    { code: "NA", name: "Namibia", countryCode: "+264" },
    { code: "NC", name: "New Caledonia", countryCode: "+687" },
    { code: "NE", name: "Niger", countryCode: "+227" },
    { code: "NF", name: "Norfolk Island", countryCode: "+672" },
    { code: "NG", name: "Nigeria", countryCode: "+234" },
    { code: "NI", name: "Nicaragua", countryCode: "+505" },
    { code: "NL", name: "Netherlands", countryCode: "+31" },
    { code: "NO", name: "Norway", countryCode: "+47" },
    { code: "NP", name: "Nepal", countryCode: "+977" },
    { code: "NR", name: "Nauru", countryCode: "+674" },
    { code: "NU", name: "Niue", countryCode: "+683" },
    { code: "NZ", name: "New Zealand", countryCode: "+64" },
    { code: "OM", name: "Oman", countryCode: "+968" },
    { code: "PA", name: "Panama", countryCode: "+507" },
    { code: "PE", name: "Peru", countryCode: "+51" },
    { code: "PF", name: "French Polynesia", countryCode: "+689" },
    { code: "PG", name: "Papua New Guinea", countryCode: "+675" },
    { code: "PH", name: "Philippines", countryCode: "+63" },
    { code: "PK", name: "Pakistan", countryCode: "+92" },
    { code: "PL", name: "Poland", countryCode: "+48" },
    {
      code: "PM",
      name: "Saint Pierre and Miquelon",
      countryCode: "+508",
    },
    { code: "PN", name: "Pitcairn", countryCode: "+870" },
    { code: "PR", name: "Puerto Rico", countryCode: "+1" },
    {
      code: "PS",
      name: "Palestine, State of",
      countryCode: "+970",
    },
    { code: "PT", name: "Portugal", countryCode: "+351" },
    { code: "PW", name: "Palau", countryCode: "+680" },
    { code: "PY", name: "Paraguay", countryCode: "+595" },
    { code: "QA", name: "Qatar", countryCode: "+974" },
    { code: "RE", name: "Reunion", countryCode: "+262" },
    { code: "RO", name: "Romania", countryCode: "+40" },
    { code: "RS", name: "Serbia", countryCode: "+381" },
    { code: "RU", name: "Russian Federation", countryCode: "+7" },
    { code: "RW", name: "Rwanda", countryCode: "+250" },
    { code: "SA", name: "Saudi Arabia", countryCode: "+966" },
    { code: "SB", name: "Solomon Islands", countryCode: "+677" },
    { code: "SC", name: "Seychelles", countryCode: "+248" },
    { code: "SD", name: "Sudan", countryCode: "+249" },
    { code: "SE", name: "Sweden", countryCode: "+46" },
    { code: "SG", name: "Singapore", countryCode: "+65" },
    { code: "SH", name: "Saint Helena", countryCode: "+290" },
    { code: "SI", name: "Slovenia", countryCode: "+386" },
    {
      code: "SJ",
      name: "Svalbard and Jan Mayen",
      countryCode: "+47",
    },
    { code: "SK", name: "Slovakia", countryCode: "+421" },
    { code: "SL", name: "Sierra Leone", countryCode: "+232" },
    { code: "SM", name: "San Marino", countryCode: "+378" },
    { code: "SN", name: "Senegal", countryCode: "+221" },
    { code: "SO", name: "Somalia", countryCode: "+252" },
    { code: "SR", name: "Suriname", countryCode: "+597" },
    { code: "SS", name: "South Sudan", countryCode: "+211" },
    {
      code: "ST",
      name: "Sao Tome and Principe",
      countryCode: "+239",
    },
    { code: "SV", name: "El Salvador", countryCode: "+503" },
    {
      code: "SX",
      name: "Sint Maarten (Dutch part)",
      countryCode: "+1-721",
    },
    {
      code: "SY",
      name: "Syrian Arab Republic",
      countryCode: "+963",
    },
    { code: "SZ", name: "Swaziland", countryCode: "+268" },
    {
      code: "TC",
      name: "Turks and Caicos Islands",
      countryCode: "+1-649",
    },
    { code: "TD", name: "Chad", countryCode: "+235" },
    {
      code: "TF",
      name: "French Southern Territories",
      countryCode: "+262",
    },
    { code: "TG", name: "Togo", countryCode: "+228" },
    { code: "TH", name: "Thailand", countryCode: "+66" },
    { code: "TJ", name: "Tajikistan", countryCode: "+992" },
    { code: "TK", name: "Tokelau", countryCode: "+690" },
    { code: "TL", name: "Timor-Leste", countryCode: "+670" },
    { code: "TM", name: "Turkmenistan", countryCode: "+993" },
    { code: "TN", name: "Tunisia", countryCode: "+216" },
    { code: "TO", name: "Tonga", countryCode: "+676" },
    { code: "TR", name: "Turkey", countryCode: "+90" },
    {
      code: "TT",
      name: "Trinidad and Tobago",
      countryCode: "+1-868",
    },
    { code: "TV", name: "Tuvalu", countryCode: "+688" },
    {
      code: "TW",
      name: "Taiwan",
      countryCode: "+886",
    },
    {
      code: "TZ",
      name: "United Republic of Tanzania",
      countryCode: "+255",
    },
    { code: "UA", name: "Ukraine", countryCode: "+380" },
    { code: "UG", name: "Uganda", countryCode: "+256" },
    {
      code: "US",
      name: "United States",
      countryCode: "+1",
      suggested: true,
    },
    { code: "UY", name: "Uruguay", countryCode: "+598" },
    { code: "UZ", name: "Uzbekistan", countryCode: "+998" },
    {
      code: "VA",
      name: "Holy See (Vatican City State)",
      countryCode: "+379",
    },
    {
      code: "VC",
      name: "Saint Vincent and the Grenadines",
      countryCode: "+1-784",
    },
    { code: "VE", name: "Venezuela", countryCode: "+58" },
    {
      code: "VG",
      name: "British Virgin Islands",
      countryCode: "+1-284",
    },
    {
      code: "VI",
      name: "US Virgin Islands",
      countryCode: "+1-340",
    },
    { code: "VN", name: "Vietnam", countryCode: "+84" },
    { code: "VU", name: "Vanuatu", countryCode: "+678" },
    { code: "WF", name: "Wallis and Futuna", countryCode: "+681" },
    { code: "WS", name: "Samoa", countryCode: "+685" },
    { code: "XK", name: "Kosovo", countryCode: "+383" },
    { code: "YE", name: "Yemen", countryCode: "+967" },
    { code: "YT", name: "Mayotte", countryCode: "+262" },
    { code: "ZA", name: "South Africa", countryCode: "+27" },
    { code: "ZM", name: "Zambia", countryCode: "+260" },
    { code: "ZW", name: "Zimbabwe", countryCode: "+263" },
  ]

  const mobileNumberOptions = countryList.map(country => ({
    value: country.countryCode,
    label: `${country.name} (${country.countryCode})`,
  }))

  const renderUboSections = () => {
    const uboSections = []
    for (let i = 1; i <= uboCount; i++) {
      console.log(i, uboCount, "Inside render for loop!!!")
      const passportDocDetails =
        i === 1
          ? uboPassportPreview_1
          : i === 2
          ? uboPassportPreview_2
          : uboPassportPreview_3
      const identityCardDocDetails =
        i === 1
          ? uboIdentityCardPreview_1
          : i === 2
          ? uboIdentityCardPreview_2
          : uboIdentityCardPreview_3
      const shareholdingRegisterDocDetails =
        i === 1
          ? shareholdingRegisterPreview_1
          : i === 2
          ? shareholdingRegisterPreview_2
          : shareholdingRegisterPreview_3
      const residenceProofDocDetails =
        i === 1
          ? uboResidenceProofPreview_1
          : i === 2
          ? uboResidenceProofPreview_2
          : uboResidenceProofPreview_3

      uboSections.push(
        <div key={i} className="custom-border mb-4">
          <h3>UBO {i} Details</h3>
          <FormGroup className="mb-4">
            <div className="row ">
              <div className="col-lg-4">
                <Label
                  htmlFor={`uboFname_${i}`}
                  className="col-form-label col-lg-12"
                >
                  First Name
                </Label>
                <Input
                  id={`uboFname_${i}`}
                  name={`uboFname_${i}`}
                  type="text"
                  placeholder="Enter First Name..."
                  value={formik.values["uboFname_" + i]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`${
                    formik.touched["uboFname_" + i] &&
                    formik.errors["uboFname_" + i]
                      ? "is-invalid"
                      : ""
                  }`}
                  invalid={
                    formik.touched["uboFname_" + i] &&
                    Boolean(formik.errors["uboFname_" + i])
                  }
                />
                {formik.touched["uboFname_" + i] &&
                formik.errors["uboFname_" + i] ? (
                  <FormFeedback>{formik.errors["uboFname_" + i]}</FormFeedback>
                ) : null}
              </div>
              <div className="col-lg-4">
                <Label
                  htmlFor={`uboLname_${i}`}
                  className="col-form-label col-lg-12"
                >
                  Last Name
                </Label>
                <Input
                  id={`uboLname_${i}`}
                  name={`uboLname_${i}`}
                  type="text"
                  placeholder="Enter Last Name..."
                  value={formik.values["uboLname_" + i]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`${
                    formik.touched["uboLname_" + i] &&
                    formik.errors["uboLname_" + i]
                      ? "is-invalid"
                      : ""
                  }`}
                  invalid={
                    formik.touched["uboLname_" + i] &&
                    Boolean(formik.errors["uboLname_" + i])
                  }
                />
                {formik.touched["uboLname_" + i] &&
                formik.errors["uboLname_" + i] ? (
                  <FormFeedback>{formik.errors["uboLname_" + i]}</FormFeedback>
                ) : null}
              </div>
            </div>
          </FormGroup>
          <FormGroup className="mb-4">
            <div className="row ">
              <div className="col-lg-3">
                <Label
                  htmlFor={`uboCountryCode_${i}`}
                  className="col-form-label"
                >
                  Country Code
                </Label>
                <Col>
                  <Select
                    isMulti={false}
                    id={`uboCountryCode_${i}`}
                    name={`uboCountryCode_${i}`}
                    options={mobileNumberOptions}
                    placeholder="Select country code"
                    value={formik.values["uboCountryCode_" + i]}
                    onChange={option =>
                      formik.setFieldValue(`uboCountryCode_${i}`, option)
                    }
                    onBlur={() =>
                      formik.setFieldTouched(`uboCountryCode_${i}`, true)
                    }
                    className={
                      formik.touched["uboCountryCode_" + i] &&
                      formik.errors["uboCountryCode_" + i]
                        ? "is-invalid"
                        : ""
                    }
                  />
                  {formik.touched["uboCountryCode_" + i] &&
                  formik.errors["uboCountryCode_" + i] ? (
                    <FormFeedback>
                      {formik.errors["uboCountryCode_" + i]}
                    </FormFeedback>
                  ) : null}
                </Col>
              </div>
              <div className="col-lg-5">
                <Label htmlFor={`uboPhn_${i}`} className="col-form-label">
                  Mobile Number
                </Label>
                <Input
                  id={`uboPhn_${i}`}
                  name={`uboPhn_${i}`}
                  type="text"
                  placeholder="Enter Mobile Number..."
                  value={formik.values["uboPhn_" + i]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`${
                    formik.touched["uboPhn_" + i] &&
                    formik.errors["uboPhn_" + i]
                      ? "is-invalid"
                      : ""
                  }`}
                  invalid={
                    formik.touched["uboPhn_" + i] &&
                    Boolean(formik.errors["uboPhn_" + i])
                  }
                />
                {formik.touched["uboPhn_" + i] &&
                formik.errors["uboPhn_" + i] ? (
                  <FormFeedback>{formik.errors["uboPhn_" + i]}</FormFeedback>
                ) : null}
              </div>
            </div>
          </FormGroup>
          <div className="mb-4">
            <h4 className="mb-4">Identity Proof</h4>
            <div className="mb-3">
              <div className="col-lg-4 mb-2">
                <FormGroup check>
                  <Input
                    id={`uboPassport_${i}`}
                    name={`uboIdproof_${i}`}
                    type="radio"
                    onClick={() => {
                      setUboIdProofs(prevIdProofs => {
                        const newIdProofs = [...prevIdProofs]
                        newIdProofs[i - 1] = "passport"
                        console.log(uboIdProofs[i - 1], "Testing!!!")
                        return newIdProofs
                      })
                    }}
                    checked={
                      uboIdProofs[i - 1] === "passport" ||
                      uboIdProofs[i - 1] === "passaporto"
                    }
                    readOnly
                  />
                  <Label check htmlFor={`uboPassport_${i}`}>
                    Passport
                  </Label>
                </FormGroup>
              </div>
              <div className="col-lg-4">
                <FormGroup check>
                  <Input
                    id={`uboIdcard_${i}`}
                    name={`uboIdproof_${i}`}
                    type="radio"
                    onClick={() => {
                      setUboIdProofs(prevIdProofs => {
                        const newIdProofs = [...prevIdProofs]
                        newIdProofs[i - 1] = "identitycard"
                        console.log(uboIdProofs[i - 1], "Testing!!!")
                        return newIdProofs
                      })
                    }}
                    checked={
                      uboIdProofs[i - 1] === "identitycard" ||
                      uboIdProofs[i - 1] === "carta d'identità"
                    }
                    readOnly
                  />
                  <Label check htmlFor={`uboIdcard_${i}`}>
                    Identity Card
                  </Label>
                </FormGroup>
              </div>
            </div>

            {(uboIdProofs[i - 1] === "passport" ||
              uboIdProofs[i - 1] === "passaporto") && (
              <FormGroup className="mb-4">
                <div className="row mb-4">
                  <div className="col-lg-8">
                    <Label
                      htmlFor={`uboPassportNum_${i}`}
                      className="col-form-label"
                    >
                      Passport Number
                    </Label>
                    <Input
                      id={`uboPassportNum_${i}`}
                      name={`uboPassportNum_${i}`}
                      type="text"
                      placeholder="Enter Passport Number..."
                      value={formik.values["uboPassportNum_" + i]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`${
                        formik.touched["uboPassportNum_" + i] &&
                        formik.errors["uboPassportNum_" + i]
                          ? "is-invalid"
                          : ""
                      }`}
                      invalid={
                        formik.touched["uboPassportNum_" + i] &&
                        Boolean(formik.errors["uboPassportNum_" + i])
                      }
                    />
                    {formik.touched["uboPassportNum_" + i] &&
                    formik.errors["uboPassportNum_" + i] ? (
                      <FormFeedback>
                        {formik.errors["uboPassportNum_" + i]}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-8">
                    <Label
                      htmlFor={`uboExpiryDatePassport_${i}`}
                      className="col-form-label"
                    >
                      Passport Expiry
                    </Label>
                    <Input
                      id={`uboExpiryDatePassport_${i}`}
                      name={`uboExpiryDatePassport_${i}`}
                      type="date"
                      min={getTodayDate()}
                      value={formik.values["uboExpiryDatePassport_" + i]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      invalid={
                        formik.touched["uboExpiryDatePassport_" + i] &&
                        formik.errors["uboExpiryDatePassport_" + i]
                          ? true
                          : false
                      }
                      className={`${
                        formik.touched["uboExpiryDatePassport_" + i] &&
                        formik.errors["uboExpiryDatePassport_" + i]
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    {formik.touched["uboExpiryDatePassport_" + i] &&
                    formik.errors["uboExpiryDatePassport_" + i] ? (
                      <FormFeedback>
                        {formik.errors["uboExpiryDatePassport_" + i]}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-8">
                    <Label
                      htmlFor={`uboPassportFile_${i}`}
                      className="col-form-label"
                    >
                      Passport Document
                    </Label>
                    <Input
                      id={`uboPassportFile_${i}`}
                      name={`uboPassportFile_${i}`}
                      type="file"
                      accept="image/*,application/pdf"
                      // invalid={formik.touched.passportFile && Boolean(formik.errors.passportFile)}
                      onChange={e => handleUpload(e, `uboPassportFile_${i}`)}
                      onBlur={formik.handleBlur}
                      className={
                        formik.touched["uboPassportFile_" + i] &&
                        formik.errors["uboPassportFile_" + i]
                          ? "is-invalid"
                          : ""
                      }
                    />
                    {renderPreview(
                      i === 1
                        ? uboPassportPreview_1.filePreviewObj
                        : i === 2
                        ? uboPassportPreview_2.filePreviewObj
                        : uboPassportPreview_3.filePreviewObj,
                      i === 1
                        ? uboPassportPreview_1.fileType
                        : i === 2
                        ? uboPassportPreview_2.fileType
                        : uboPassportPreview_3.fileType,
                      i === 1
                        ? uboPassportPreview_1.fileName
                        : i === 2
                        ? uboPassportPreview_2.fileName
                        : uboPassportPreview_3.fileName
                    )}
                    {formik.touched["uboPassportFile_" + i] &&
                    formik.errors["uboPassportFile_" + i] ? (
                      <FormFeedback>
                        {formik.errors["uboPassportFile_" + i]}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
              </FormGroup>
            )}
            {(uboIdProofs[i - 1] === "identitycard" ||
              uboIdProofs[i - 1] === "carta d'identità") && (
              <FormGroup className="mb-4">
                <div className="row mb-4">
                  <div className="col-lg-8">
                    <Label htmlFor={`uboIdNum_${i}`} className="col-form-label">
                      Identity Card Number
                    </Label>
                    <Input
                      id={`uboIdNum_${i}`}
                      name={`uboIdNum_${i}`}
                      type="text"
                      placeholder="Enter ID Number..."
                      value={formik.values["uboIdNum_" + i]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`${
                        formik.touched["uboIdNum_" + i] &&
                        formik.errors["uboIdNum_" + i]
                          ? "is-invalid"
                          : ""
                      }`}
                      invalid={
                        formik.touched["uboIdNum_" + i] &&
                        Boolean(formik.errors["uboIdNum_" + i])
                      }
                    />
                    {formik.touched["uboIdNum_" + i] &&
                    formik.errors["uboIdNum_" + i] ? (
                      <FormFeedback>
                        {formik.errors["uboIdNum_" + i]}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-8">
                    <Label
                      htmlFor={`uboExpiryDateIDProof_${i}`}
                      className="col-form-label"
                    >
                      ID Expiry
                    </Label>
                    <Input
                      id={`uboExpiryDateIDProof_${i}`}
                      name={`uboExpiryDateIDProof_${i}`}
                      type="date"
                      min={getTodayDate()}
                      value={formik.values["uboExpiryDateIDProof_" + i]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      invalid={
                        formik.touched["uboExpiryDateIDProof_" + i] &&
                        formik.errors["uboExpiryDateIDProof_" + i]
                          ? true
                          : false
                      }
                      className={`${
                        formik.touched["uboExpiryDateIDProof_" + i] &&
                        formik.errors["uboExpiryDateIDProof_" + i]
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    {formik.touched["uboExpiryDateIDProof_" + i] &&
                    formik.errors["uboExpiryDateIDProof_" + i] ? (
                      <FormFeedback>
                        {formik.errors["uboExpiryDateIDProof_" + i]}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-8">
                    <Label
                      htmlFor={`uboIdFile_${i}`}
                      className="col-form-label"
                    >
                      ID Document
                    </Label>
                    <Input
                      id={`uboIdFile_${i}`}
                      name={`uboIdFile_${i}`}
                      type="file"
                      accept="image/*,application/pdf"
                      // invalid={formik.touched.passportFile && Boolean(formik.errors.passportFile)}
                      onChange={e => handleUpload(e, `uboIdFile_${i}`)}
                      onBlur={formik.handleBlur}
                      className={
                        formik.touched["uboIdFile_" + i] &&
                        formik.errors["uboIdFile_" + i]
                          ? "is-invalid"
                          : ""
                      }
                    />
                    {renderPreview(
                      i === 1
                        ? uboIdentityCardPreview_1.filePreviewObj
                        : i === 2
                        ? uboIdentityCardPreview_2.filePreviewObj
                        : uboIdentityCardPreview_3.filePreviewObj,
                      i === 1
                        ? uboIdentityCardPreview_1.fileType
                        : i === 2
                        ? uboIdentityCardPreview_2.fileType
                        : uboIdentityCardPreview_3.fileType,
                      i === 1
                        ? uboIdentityCardPreview_1.fileName
                        : i === 2
                        ? uboIdentityCardPreview_2.fileName
                        : uboIdentityCardPreview_3.fileName
                    )}
                    {formik.touched["uboIdFile_" + i] &&
                    formik.errors["uboIdFile_" + i] ? (
                      <FormFeedback>
                        {formik.errors["uboIdFile_" + i]}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
              </FormGroup>
            )}
          </div>
          <div className="mb-4">
            <h4 className="mb-4">Residence Proof</h4>
            <FormGroup className="mb-4">
              <div className="row mb-4">
                <div className="col-lg-4">
                  <Label htmlFor={`uboZipcode_${i}`} className="col-form-label">
                    Zip Code
                  </Label>
                  <Input
                    id={`uboZipcode_${i}`}
                    name={`uboZipcode_${i}`}
                    type="text"
                    placeholder="Enter Zip code..."
                    value={formik.values["uboZipcode_" + i]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`${
                      formik.touched["uboZipcode_" + i] &&
                      formik.errors["uboZipcode_" + i]
                        ? "is-invalid"
                        : ""
                    }`}
                    invalid={
                      formik.touched["uboZipcode_" + i] &&
                      Boolean(formik.errors["uboZipcode_" + i])
                    }
                  />
                  {formik.touched["uboZipcode_" + i] &&
                  formik.errors["uboZipcode_" + i] ? (
                    <FormFeedback>
                      {formik.errors["uboZipcode_" + i]}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="col-lg-4">
                  <Label
                    className="col-form-label col-lg-12"
                    htmlFor={`uboCountry_${i}`}
                  >
                    Country
                  </Label>
                  <Select
                    isMulti={false}
                    id={`uboCountry_${i}`}
                    name={`uboCountry_${i}`}
                    options={residenceCountry}
                    value={formik.values["uboCountry_" + i]}
                    onChange={option =>
                      formik.setFieldValue(`uboCountry_${i}`, option)
                    }
                    onBlur={() =>
                      formik.setFieldTouched(`uboCountry_${i}`, true)
                    }
                    invalid={
                      formik.touched["uboCountry_" + i] &&
                      formik.errors["uboCountry_" + i]
                        ? true
                        : false
                    }
                    className={
                      formik.touched["uboCountry_" + i] &&
                      formik.errors["uboCountry_" + i]
                        ? "is-invalid"
                        : ""
                    }
                  />
                  {formik.touched["uboCountry_" + i] &&
                  formik.errors["uboCountry_" + i] ? (
                    <FormFeedback>
                      {formik.errors["uboCountry_" + i]}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-4">
                  <Label
                    htmlFor={`uboProvince_${i}`}
                    className="col-form-label"
                  >
                    Province
                  </Label>
                  <Input
                    id={`uboProvince_${i}`}
                    name={`uboProvince_${i}`}
                    type="text"
                    placeholder="Enter Province..."
                    value={formik.values["uboProvince_" + i]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`${
                      formik.touched["uboProvince_" + i] &&
                      formik.errors["uboProvince_" + i]
                        ? "is-invalid"
                        : ""
                    }`}
                    invalid={
                      formik.touched["uboProvince_" + i] &&
                      Boolean(formik.errors["uboProvince_" + i])
                    }
                  />
                  {formik.touched["uboProvince_" + i] &&
                  formik.errors["uboProvince_" + i] ? (
                    <FormFeedback>
                      {formik.errors["uboProvince_" + i]}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="col-lg-4">
                  <Label htmlFor={`uboRegion_${i}`} className="col-form-label">
                    Region
                  </Label>
                  <Input
                    id={`uboRegion_${i}`}
                    name={`uboRegion_${i}`}
                    type="text"
                    placeholder="Enter Region..."
                    value={formik.values["uboRegion_" + i]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`${
                      formik.touched["uboRegion_" + i] &&
                      formik.errors["uboRegion_" + i]
                        ? "is-invalid"
                        : ""
                    }`}
                    invalid={
                      formik.touched["uboRegion_" + i] &&
                      Boolean(formik.errors["uboRegion_" + i])
                    }
                  />
                  {formik.touched["uboRegion_" + i] &&
                  formik.errors["uboRegion_" + i] ? (
                    <FormFeedback>
                      {formik.errors["uboRegion_" + i]}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-4">
                  <Label htmlFor={`uboCity_${i}`} className="col-form-label">
                    City
                  </Label>
                  <Input
                    id={`uboCity_${i}`}
                    name={`uboCity_${i}`}
                    type="text"
                    placeholder="Enter City..."
                    value={formik.values["uboCity_" + i]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`${
                      formik.touched["uboCity_" + i] &&
                      formik.errors["uboCity_" + i]
                        ? "is-invalid"
                        : ""
                    }`}
                    invalid={
                      formik.touched["uboCity_" + i] &&
                      Boolean(formik.errors["uboCity_" + i])
                    }
                  />
                  {formik.touched["uboCity_" + i] &&
                  formik.errors["uboCity_" + i] ? (
                    <FormFeedback>{formik.errors["uboCity_" + i]}</FormFeedback>
                  ) : null}
                </div>
                <div className="col-lg-4">
                  <Label htmlFor={`uboHouseNo_${i}`} className="col-form-label">
                    House Number
                  </Label>
                  <Input
                    id={`uboHouseNo_${i}`}
                    name={`uboHouseNo_${i}`}
                    type="text"
                    placeholder="Enter House number..."
                    value={formik.values["uboHouseNo_" + i]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`${
                      formik.touched["uboHouseNo_" + i] &&
                      formik.errors["uboHouseNo_" + i]
                        ? "is-invalid"
                        : ""
                    }`}
                    invalid={
                      formik.touched["uboHouseNo_" + i] &&
                      Boolean(formik.errors["uboHouseNo_" + i])
                    }
                  />
                  {formik.touched["uboHouseNo_" + i] &&
                  formik.errors["uboHouseNo_" + i] ? (
                    <FormFeedback>
                      {formik.errors["uboHouseNo_" + i]}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-8">
                  <Label
                    htmlFor={`uboStreetArea_${i}`}
                    className="col-form-label"
                  >
                    Street/Locality/Area
                  </Label>
                  <Input
                    id={`uboStreetArea_${i}`}
                    name={`uboStreetArea_${i}`}
                    type="text"
                    placeholder="Street/Locality/Area..."
                    value={formik.values["uboStreetArea_" + i]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`${
                      formik.touched["uboStreetArea_" + i] &&
                      formik.errors["uboStreetArea_" + i]
                        ? "is-invalid"
                        : ""
                    }`}
                    invalid={
                      formik.touched["uboStreetArea_" + i] &&
                      Boolean(formik.errors["uboStreetArea_" + i])
                    }
                  />
                  {formik.touched["uboStreetArea_" + i] &&
                  formik.errors["uboStreetArea_" + i] ? (
                    <FormFeedback>
                      {formik.errors["uboStreetArea_" + i]}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-lg-8">
                  <Label
                    className="col-form-label col-lg-12"
                    htmlFor={`uboDocumentType_${i}`}
                  >
                    Choose Address Document
                  </Label>
                  <Select
                    isMulti={false}
                    id={`uboDocumentType_${i}`}
                    name={`uboDocumentType_${i}`}
                    options={documentTypeList}
                    value={formik.values["uboDocumentType_" + i]}
                    onChange={option =>
                      formik.setFieldValue(`uboDocumentType_${i}`, option)
                    }
                    onBlur={() =>
                      formik.setFieldTouched(`uboDocumentType_${i}`, true)
                    }
                    invalid={
                      formik.touched["uboDocumentType_" + i] &&
                      formik.errors["uboDocumentType_" + i]
                        ? true
                        : false
                    }
                    className={
                      formik.touched["uboDocumentType_" + i] &&
                      formik.errors["uboDocumentType_" + i]
                        ? "is-invalid"
                        : ""
                    }
                  />
                  {formik.touched["uboDocumentType_" + i] &&
                  formik.errors["uboDocumentType_" + i] ? (
                    <FormFeedback>
                      {formik.errors["uboDocumentType_" + i]}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-8">
                  <Label
                    htmlFor={`uboDocumentDate_${i}`}
                    className="col-form-label"
                  >
                    Issuance Date
                  </Label>
                  <Input
                    id={`uboDocumentDate_${i}`}
                    name={`uboDocumentDate_${i}`}
                    type="date"
                    max={getTodayDate()}
                    value={formik.values["uboDocumentDate_" + i]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={
                      formik.touched["uboDocumentDate_" + i] &&
                      formik.errors["uboDocumentDate_" + i]
                        ? true
                        : false
                    }
                    className={`${
                      formik.touched["uboDocumentDate_" + i] &&
                      formik.errors["uboDocumentDate_" + i]
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {formik.touched["uboDocumentDate_" + i] &&
                  formik.errors["uboDocumentDate_" + i] ? (
                    <FormFeedback>
                      {formik.errors["uboDocumentDate_" + i]}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-8">
                  <Label
                    htmlFor={`uboDocumentFile_${i}`}
                    className="col-form-label"
                  >
                    Address Document
                  </Label>
                  <Input
                    id={`uboDocumentFile_${i}`}
                    name={`uboDocumentFile_${i}`}
                    type="file"
                    accept="image/*,application/pdf"
                    // invalid={formik.touched.passportFile && Boolean(formik.errors.passportFile)}
                    onChange={e => handleUpload(e, `uboDocumentFile_${i}`)}
                    onBlur={formik.handleBlur}
                    className={
                      formik.touched["uboDocumentFile_" + i] &&
                      formik.errors["uboDocumentFile_" + i]
                        ? "is-invalid"
                        : ""
                    }
                  />
                  {renderPreview(
                    i === 1
                      ? uboResidenceProofPreview_1.filePreviewObj
                      : i === 2
                      ? uboResidenceProofPreview_2.filePreviewObj
                      : uboResidenceProofPreview_3.filePreviewObj,
                    i === 1
                      ? uboResidenceProofPreview_1.fileType
                      : i === 2
                      ? uboResidenceProofPreview_2.fileType
                      : uboResidenceProofPreview_3.fileType,
                    i === 1
                      ? uboResidenceProofPreview_1.fileName
                      : i === 2
                      ? uboResidenceProofPreview_2.fileName
                      : uboResidenceProofPreview_3.fileName
                  )}
                  {formik.touched["uboDocumentFile_" + i] &&
                  formik.errors["uboDocumentFile_" + i] ? (
                    <FormFeedback>
                      {formik.errors["uboDocumentFile_" + i]}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </FormGroup>
          </div>
          <FormGroup className="mb-4">
            <div className="row">
              <Label
                htmlFor={`uboShareHoldingRegister_${i}`}
                className="col-form-label col-lg-8"
              >
                Shareholding Register
              </Label>
              <Col lg="8">
                <Input
                  id={`uboShareHoldingRegister_${i}`}
                  name={`uboShareHoldingRegister_${i}`}
                  type="file"
                  accept="image/*,application/pdf"
                  onChange={e =>
                    handleUpload(e, `uboShareHoldingRegister_${i}`)
                  }
                  onBlur={formik.handleBlur}
                  className={
                    formik.touched["uboShareHoldingRegister_" + i] &&
                    formik.errors["uboShareHoldingRegister_" + i]
                      ? "is-invalid"
                      : ""
                  }
                />
                {renderPreview(
                  i === 1
                    ? shareholdingRegisterPreview_1.filePreviewObj
                    : i === 2
                    ? shareholdingRegisterPreview_2.filePreviewObj
                    : shareholdingRegisterPreview_3.filePreviewObj,
                  i === 1
                    ? shareholdingRegisterPreview_1.fileType
                    : i === 2
                    ? shareholdingRegisterPreview_2.fileType
                    : shareholdingRegisterPreview_3.fileType,
                  i === 1
                    ? shareholdingRegisterPreview_1.fileName
                    : i === 2
                    ? shareholdingRegisterPreview_2.fileName
                    : shareholdingRegisterPreview_3.fileName
                )}
                {formik.touched["uboShareHoldingRegister_" + i] &&
                formik.errors["uboShareHoldingRegister_" + i] ? (
                  <FormFeedback>
                    {formik.errors["uboShareHoldingRegister_" + i]}
                  </FormFeedback>
                ) : null}
              </Col>
            </div>
          </FormGroup>
        </div>
      )
    }
    return uboSections
  }

  const renderLegalRepSections = () => {
    const legalRepSections = []
    for (let i = 1; i <= legalRepCount; i++) {
      const passportDocDetails =
        i === 1
          ? lrPassportPreview_1
          : i === 2
          ? lrPassportPreview_2
          : lrPassportPreview_3
      const identityCardDocDetails =
        i === 1
          ? lrIdentityCardPreview_1
          : i === 2
          ? lrIdentityCardPreview_2
          : lrIdentityCardPreview_3
      const residenceProofDocDetails =
        i === 1
          ? lrResidenceProofPreview_1
          : i === 2
          ? lrResidenceProofPreview_2
          : lrResidenceProofPreview_3

      legalRepSections.push(
        <div key={i} className="custom-border mb-4">
          <h3>Legal Representative {i} Details</h3>
          <FormGroup className="mb-4">
            <div className="row ">
              <div className="col-lg-4">
                <Label
                  htmlFor={`lrFname_${i}`}
                  className="col-form-label col-lg-12"
                >
                  First Name
                </Label>
                <Input
                  id={`lrFname_${i}`}
                  name={`lrFname_${i}`}
                  type="text"
                  placeholder="Enter First Name..."
                  value={formik.values["lrFname_" + i]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`${
                    formik.touched["lrFname_" + i] &&
                    formik.errors["lrFname_" + i]
                      ? "is-invalid"
                      : ""
                  }`}
                  invalid={
                    formik.touched["lrFname_" + i] &&
                    Boolean(formik.errors["lrFname_" + i])
                  }
                />
                {formik.touched["lrFname_" + i] &&
                formik.errors["lrFname_" + i] ? (
                  <FormFeedback>{formik.errors["lrFname_" + i]}</FormFeedback>
                ) : null}
              </div>
              <div className="col-lg-4">
                <Label
                  htmlFor={`lrLname_${i}`}
                  className="col-form-label col-lg-12"
                >
                  Last Name
                </Label>
                <Input
                  id={`lrLname_${i}`}
                  name={`lrLname_${i}`}
                  type="text"
                  placeholder="Enter Last Name..."
                  value={formik.values["lrLname_" + i]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`${
                    formik.touched["lrLname_" + i] &&
                    formik.errors["lrLname_" + i]
                      ? "is-invalid"
                      : ""
                  }`}
                  invalid={
                    formik.touched["lrLname_" + i] &&
                    Boolean(formik.errors["lrLname_" + i])
                  }
                />
                {formik.touched["lrLname_" + i] &&
                formik.errors["lrLname_" + i] ? (
                  <FormFeedback>{formik.errors["lrLname_" + i]}</FormFeedback>
                ) : null}
              </div>
            </div>
          </FormGroup>
          <FormGroup className="mb-4">
            <div className="row ">
              <div className="col-lg-3">
                <Label
                  htmlFor={`lrCountryCode_${i}`}
                  className="col-form-label"
                >
                  Country Code
                </Label>
                <Col>
                  <Select
                    isMulti={false}
                    id={`lrCountryCode_${i}`}
                    name={`lrCountryCode_${i}`}
                    options={mobileNumberOptions}
                    placeholder="Select country code"
                    value={formik.values["lrCountryCode_" + i]}
                    onChange={option =>
                      formik.setFieldValue(`lrCountryCode_${i}`, option)
                    }
                    onBlur={() =>
                      formik.setFieldTouched(`lrCountryCode_${i}`, true)
                    }
                    className={
                      formik.touched["lrCountryCode_" + i] &&
                      formik.errors["lrCountryCode_" + i]
                        ? "is-invalid"
                        : ""
                    }
                  />
                  {formik.touched["lrCountryCode_" + i] &&
                  formik.errors["lrCountryCode_" + i] ? (
                    <FormFeedback>
                      {formik.errors["lrCountryCode_" + i]}
                    </FormFeedback>
                  ) : null}
                </Col>
              </div>
              <div className="col-lg-5">
                <Label htmlFor={`lrPhn_${i}`} className="col-form-label">
                  Mobile Number
                </Label>
                <Input
                  id={`lrPhn_${i}`}
                  name={`lrPhn_${i}`}
                  type="text"
                  placeholder="Enter Mobile Number..."
                  value={formik.values["lrPhn_" + i]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`${
                    formik.touched["lrPhn_" + i] && formik.errors["lrPhn_" + i]
                      ? "is-invalid"
                      : ""
                  }`}
                  invalid={
                    formik.touched["lrPhn_" + i] &&
                    Boolean(formik.errors["lrPhn_" + i])
                  }
                />
                {formik.touched["lrPhn_" + i] && formik.errors["lrPhn_" + i] ? (
                  <FormFeedback>{formik.errors["lrPhn_" + i]}</FormFeedback>
                ) : null}
              </div>
            </div>
          </FormGroup>
          <div className="mb-4">
            <h4 className="mb-4">Identity Proof</h4>
            <div className="mb-3">
              <div className="col-lg-4 mb-2">
                <FormGroup check>
                  <Input
                    id={`lrPassport_${i}`}
                    name={`lrIdproof_${i}`}
                    type="radio"
                    onClick={() => {
                      setLrIdProofs(prevIdProofs => {
                        const newIdProofs = [...prevIdProofs]
                        newIdProofs[i - 1] = "passport"
                        return newIdProofs
                      })
                    }}
                    checked={
                      lrIdProofs[i - 1] === "passport" ||
                      lrIdProofs[i - 1] === "passaporto"
                    }
                    readOnly
                  />
                  <Label check htmlFor={`lrPassport_${i}`}>
                    Passport
                  </Label>
                </FormGroup>
              </div>
              <div className="col-lg-4">
                <FormGroup check>
                  <Input
                    id={`lrIdcard_${i}`}
                    name={`lrIdproof_${i}`}
                    type="radio"
                    onClick={() => {
                      setLrIdProofs(prevIdProofs => {
                        const newIdProofs = [...prevIdProofs]
                        newIdProofs[i - 1] = "identitycard"
                        return newIdProofs
                      })
                    }}
                    checked={
                      lrIdProofs[i - 1] === "identitycard" ||
                      lrIdProofs[i - 1] === "carta d'identità"
                    }
                    readOnly
                  />
                  <Label check htmlFor={`lrIdcard_${i}`}>
                    Identity Card
                  </Label>
                </FormGroup>
              </div>
            </div>
            {(lrIdProofs[i - 1] === "passport" ||
              lrIdProofs[i - 1] === "passaporto") && (
              <FormGroup className="mb-4">
                <div className="row mb-4">
                  <div className="col-lg-8">
                    <Label
                      htmlFor={`lrPassportNum_${i}`}
                      className="col-form-label"
                    >
                      Passport Number
                    </Label>
                    <Input
                      id={`lrPassportNum_${i}`}
                      name={`lrPassportNum_${i}`}
                      type="text"
                      placeholder="Enter Passport Number..."
                      value={formik.values["lrPassportNum_" + i]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`${
                        formik.touched["lrPassportNum_" + i] &&
                        formik.errors["lrPassportNum_" + i]
                          ? "is-invalid"
                          : ""
                      }`}
                      invalid={
                        formik.touched["lrPassportNum_" + i] &&
                        Boolean(formik.errors["lrPassportNum_" + i])
                      }
                    />
                    {formik.touched["lrPassportNum_" + i] &&
                    formik.errors["lrPassportNum_" + i] ? (
                      <FormFeedback>
                        {formik.errors["lrPassportNum_" + i]}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-8">
                    <Label
                      htmlFor={`lrExpiryDatePassport_${i}`}
                      className="col-form-label"
                    >
                      Passport Expiry
                    </Label>
                    <Input
                      id={`lrExpiryDatePassport_${i}`}
                      name={`lrExpiryDatePassport_${i}`}
                      type="date"
                      min={getTodayDate()}
                      value={formik.values["lrExpiryDatePassport_" + i]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      invalid={
                        formik.touched["lrExpiryDatePassport_" + i] &&
                        formik.errors["lrExpiryDatePassport_" + i]
                          ? true
                          : false
                      }
                      className={`${
                        formik.touched["lrExpiryDatePassport_" + i] &&
                        formik.errors["lrExpiryDatePassport_" + i]
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    {formik.touched["lrExpiryDatePassport_" + i] &&
                    formik.errors["lrExpiryDatePassport_" + i] ? (
                      <FormFeedback>
                        {formik.errors["lrExpiryDatePassport_" + i]}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-8">
                    <Label
                      htmlFor={`lrPassportFile_${i}`}
                      className="col-form-label"
                    >
                      Passport Document
                    </Label>
                    <Input
                      id={`lrPassportFile_${i}`}
                      name={`lrPassportFile_${i}`}
                      type="file"
                      accept="image/*,application/pdf"
                      // invalid={formik.touched.passportFile && Boolean(formik.errors.passportFile)}
                      onChange={e => handleUpload(e, `lrPassportFile_${i}`)}
                      onBlur={formik.handleBlur}
                      className={
                        formik.touched["lrPassportFile_" + i] &&
                        formik.errors["lrPassportFile_" + i]
                          ? "is-invalid"
                          : ""
                      }
                    />
                    {renderPreview(
                      passportDocDetails.filePreviewObj,
                      passportDocDetails.fileType,
                      passportDocDetails.fileName
                    )}
                    {formik.touched["lrPassportFile_" + i] &&
                    formik.errors["lrPassportFile_" + i] ? (
                      <FormFeedback>
                        {formik.errors["lrPassportFile_" + i]}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
              </FormGroup>
            )}
            {(lrIdProofs[i - 1] === "identitycard" ||
              lrIdProofs[i - 1] === "carta d'identità") && (
              <FormGroup className="mb-4">
                <div className="row mb-4">
                  <div className="col-lg-8">
                    <Label htmlFor={`lrIdNum_${i}`} className="col-form-label">
                      Identity Card Number
                    </Label>
                    <Input
                      id={`lrIdNum_${i}`}
                      name={`lrIdNum_${i}`}
                      type="text"
                      placeholder="Enter ID Number..."
                      value={formik.values["lrIdNum_" + i]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`${
                        formik.touched["lrIdNum_" + i] &&
                        formik.errors["lrIdNum_" + i]
                          ? "is-invalid"
                          : ""
                      }`}
                      invalid={
                        formik.touched["lrIdNum_" + i] &&
                        Boolean(formik.errors["lrIdNum_" + i])
                      }
                    />
                    {formik.touched["lrIdNum_" + i] &&
                    formik.errors["lrIdNum_" + i] ? (
                      <FormFeedback>
                        {formik.errors["lrIdNum_" + i]}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-8">
                    <Label
                      htmlFor={`lrExpiryDateIDProof_${i}`}
                      className="col-form-label"
                    >
                      ID Expiry
                    </Label>
                    <Input
                      id={`lrExpiryDateIDProof_${i}`}
                      name={`lrExpiryDateIDProof_${i}`}
                      type="date"
                      min={getTodayDate()}
                      value={formik.values["lrExpiryDateIDProof_" + i]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      invalid={
                        formik.touched["lrExpiryDateIDProof_" + i] &&
                        formik.errors["lrExpiryDateIDProof_" + i]
                          ? true
                          : false
                      }
                      className={`${
                        formik.touched["lrExpiryDateIDProof_" + i] &&
                        formik.errors["lrExpiryDateIDProof_" + i]
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    {formik.touched["lrExpiryDateIDProof_" + i] &&
                    formik.errors["lrExpiryDateIDProof_" + i] ? (
                      <FormFeedback>
                        {formik.errors["lrExpiryDateIDProof_" + i]}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-8">
                    <Label htmlFor={`lrIdFile_${i}`} className="col-form-label">
                      ID Document
                    </Label>
                    <Input
                      id={`lrIdFile_${i}`}
                      name={`lrIdFile_${i}`}
                      type="file"
                      accept="image/*,application/pdf"
                      // invalid={formik.touched.passportFile && Boolean(formik.errors.passportFile)}
                      onChange={e => handleUpload(e, `lrIdFile_${i}`)}
                      onBlur={formik.handleBlur}
                      className={
                        formik.touched["lrIdFile_" + i] &&
                        formik.errors["lrIdFile_" + i]
                          ? "is-invalid"
                          : ""
                      }
                    />
                    {renderPreview(
                      identityCardDocDetails.filePreviewObj,
                      identityCardDocDetails.fileType,
                      identityCardDocDetails.fileName
                    )}
                    {formik.touched["lrIdFile_" + i] &&
                    formik.errors["lrIdFile_" + i] ? (
                      <FormFeedback>
                        {formik.errors["lrIdFile_" + i]}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
              </FormGroup>
            )}
          </div>
          <div className="mb-4">
            <h4 className="mb-4">Residence Proof</h4>
            <FormGroup className="mb-4">
              <div className="row mb-4">
                <div className="col-lg-4">
                  <Label htmlFor={`lrZipcode_${i}`} className="col-form-label">
                    Zip Code
                  </Label>
                  <Input
                    id={`lrZipcode_${i}`}
                    name={`lrZipcode_${i}`}
                    type="text"
                    placeholder="Enter Zip code..."
                    value={formik.values["lrZipcode_" + i]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`${
                      formik.touched["lrZipcode_" + i] &&
                      formik.errors["lrZipcode_" + i]
                        ? "is-invalid"
                        : ""
                    }`}
                    invalid={
                      formik.touched["lrZipcode_" + i] &&
                      Boolean(formik.errors["lrZipcode_" + i])
                    }
                  />
                  {formik.touched["lrZipcode_" + i] &&
                  formik.errors["lrZipcode_" + i] ? (
                    <FormFeedback>
                      {formik.errors["lrZipcode_" + i]}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="col-lg-4">
                  <Label
                    className="col-form-label col-lg-12"
                    htmlFor={`lrCountry_${i}`}
                  >
                    Country
                  </Label>
                  <Select
                    isMulti={false}
                    id={`lrCountry_${i}`}
                    name={`lrCountry_${i}`}
                    options={residenceCountry}
                    value={formik.values["lrCountry_" + i]}
                    onChange={option =>
                      formik.setFieldValue(`lrCountry_${i}`, option)
                    }
                    onBlur={() =>
                      formik.setFieldTouched(`lrCountry_${i}`, true)
                    }
                    invalid={
                      formik.touched["lrCountry_" + i] &&
                      formik.errors["lrCountry_" + i]
                        ? true
                        : false
                    }
                    className={
                      formik.touched["lrCountry_" + i] &&
                      formik.errors["lrCountry_" + i]
                        ? "is-invalid"
                        : ""
                    }
                  />
                  {formik.touched["lrCountry_" + i] &&
                  formik.errors["lrCountry_" + i] ? (
                    <FormFeedback>
                      {formik.errors["lrCountry_" + i]}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-4">
                  <Label htmlFor={`lrProvince_${i}`} className="col-form-label">
                    Province
                  </Label>
                  <Input
                    id={`lrProvince_${i}`}
                    name={`lrProvince_${i}`}
                    type="text"
                    placeholder="Enter Province..."
                    value={formik.values["lrProvince_" + i]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`${
                      formik.touched["lrProvince_" + i] &&
                      formik.errors["lrProvince_" + i]
                        ? "is-invalid"
                        : ""
                    }`}
                    invalid={
                      formik.touched["lrProvince_" + i] &&
                      Boolean(formik.errors["lrProvince_" + i])
                    }
                  />
                  {formik.touched["lrProvince_" + i] &&
                  formik.errors["lrProvince_" + i] ? (
                    <FormFeedback>
                      {formik.errors["lrProvince_" + i]}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="col-lg-4">
                  <Label htmlFor={`lrRegion_${i}`} className="col-form-label">
                    Region
                  </Label>
                  <Input
                    id={`lrRegion_${i}`}
                    name={`lrRegion_${i}`}
                    type="text"
                    placeholder="Enter Region..."
                    value={formik.values["lrRegion_" + i]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`${
                      formik.touched["lrRegion_" + i] &&
                      formik.errors["lrRegion_" + i]
                        ? "is-invalid"
                        : ""
                    }`}
                    invalid={
                      formik.touched["lrRegion_" + i] &&
                      Boolean(formik.errors["lrRegion_" + i])
                    }
                  />
                  {formik.touched["lrRegion_" + i] &&
                  formik.errors["lrRegion_" + i] ? (
                    <FormFeedback>
                      {formik.errors["lrRegion_" + i]}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-4">
                  <Label htmlFor={`lrCity_${i}`} className="col-form-label">
                    City
                  </Label>
                  <Input
                    id={`lrCity_${i}`}
                    name={`lrCity_${i}`}
                    type="text"
                    placeholder="Enter City..."
                    value={formik.values["lrCity_" + i]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`${
                      formik.touched["lrCity_" + i] &&
                      formik.errors["lrCity_" + i]
                        ? "is-invalid"
                        : ""
                    }`}
                    invalid={
                      formik.touched["lrCity_" + i] &&
                      Boolean(formik.errors["lrCity_" + i])
                    }
                  />
                  {formik.touched["lrCity_" + i] &&
                  formik.errors["lrCity_" + i] ? (
                    <FormFeedback>{formik.errors["lrCity_" + i]}</FormFeedback>
                  ) : null}
                </div>
                <div className="col-lg-4">
                  <Label htmlFor={`lrHouseNo_${i}`} className="col-form-label">
                    House Number
                  </Label>
                  <Input
                    id={`lrHouseNo_${i}`}
                    name={`lrHouseNo_${i}`}
                    type="text"
                    placeholder="Enter House number..."
                    value={formik.values["lrHouseNo_" + i]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`${
                      formik.touched["lrHouseNo_" + i] &&
                      formik.errors["lrHouseNo_" + i]
                        ? "is-invalid"
                        : ""
                    }`}
                    invalid={
                      formik.touched["lrHouseNo_" + i] &&
                      Boolean(formik.errors["lrHouseNo_" + i])
                    }
                  />
                  {formik.touched["lrHouseNo_" + i] &&
                  formik.errors["lrHouseNo_" + i] ? (
                    <FormFeedback>
                      {formik.errors["lrHouseNo_" + i]}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-8">
                  <Label
                    htmlFor={`lrStreetArea_${i}`}
                    className="col-form-label"
                  >
                    Street/Locality/Area
                  </Label>
                  <Input
                    id={`lrStreetArea_${i}`}
                    name={`lrStreetArea_${i}`}
                    type="text"
                    placeholder="Street/Locality/Area..."
                    value={formik.values["lrStreetArea_" + i]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`${
                      formik.touched["lrStreetArea_" + i] &&
                      formik.errors["lrStreetArea_" + i]
                        ? "is-invalid"
                        : ""
                    }`}
                    invalid={
                      formik.touched["lrStreetArea_" + i] &&
                      Boolean(formik.errors["lrStreetArea_" + i])
                    }
                  />
                  {formik.touched["lrStreetArea_" + i] &&
                  formik.errors["lrStreetArea_" + i] ? (
                    <FormFeedback>
                      {formik.errors["lrStreetArea_" + i]}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-lg-8">
                  <Label
                    className="col-form-label col-lg-12"
                    htmlFor={`lrDocumentType_${i}`}
                  >
                    Choose Address Document
                  </Label>
                  <Select
                    isMulti={false}
                    id={`lrDocumentType_${i}`}
                    name={`lrDocumentType_${i}`}
                    options={documentTypeList}
                    value={formik.values["lrDocumentType_" + i]}
                    onChange={option =>
                      formik.setFieldValue(`lrDocumentType_${i}`, option)
                    }
                    onBlur={() =>
                      formik.setFieldTouched(`lrDocumentType_${i}`, true)
                    }
                    invalid={
                      formik.touched["lrDocumentType_" + i] &&
                      formik.errors["lrDocumentType_" + i]
                        ? true
                        : false
                    }
                    className={
                      formik.touched["lrDocumentType_" + i] &&
                      formik.errors["lrDocumentType_" + i]
                        ? "is-invalid"
                        : ""
                    }
                  />
                  {formik.touched["lrDocumentType_" + i] &&
                  formik.errors["lrDocumentType_" + i] ? (
                    <FormFeedback>
                      {formik.errors["lrDocumentType_" + i]}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-8">
                  <Label
                    htmlFor={`lrDocumentDate_${i}`}
                    className="col-form-label"
                  >
                    Issuance Date
                  </Label>
                  <Input
                    id={`lrDocumentDate_${i}`}
                    name={`lrDocumentDate_${i}`}
                    type="date"
                    max={getTodayDate()}
                    value={formik.values["lrDocumentDate_" + i]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={
                      formik.touched["lrDocumentDate_" + i] &&
                      formik.errors["lrDocumentDate_" + i]
                        ? true
                        : false
                    }
                    className={`${
                      formik.touched["lrDocumentDate_" + i] &&
                      formik.errors["lrDocumentDate_" + i]
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {formik.touched["lrDocumentDate_" + i] &&
                  formik.errors["lrDocumentDate_" + i] ? (
                    <FormFeedback>
                      {formik.errors["lrDocumentDate_" + i]}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-8">
                  <Label
                    htmlFor={`lrDocumentFile_${i}`}
                    className="col-form-label"
                  >
                    Address Document
                  </Label>
                  <Input
                    id={`lrDocumentFile_${i}`}
                    name={`lrDocumentFile_${i}`}
                    type="file"
                    accept="image/*,application/pdf"
                    // invalid={formik.touched.passportFile && Boolean(formik.errors.passportFile)}
                    onChange={e => handleUpload(e, `lrDocumentFile_${i}`)}
                    onBlur={formik.handleBlur}
                    className={
                      formik.touched["lrDocumentFile_" + i] &&
                      formik.errors["lrDocumentFile_" + i]
                        ? "is-invalid"
                        : ""
                    }
                  />
                  {renderPreview(
                    residenceProofDocDetails.filePreviewObj,
                    residenceProofDocDetails.fileType,
                    residenceProofDocDetails.fileName
                  )}
                  {formik.touched["lrDocumentFile_" + i] &&
                  formik.errors["lrDocumentFile_" + i] ? (
                    <FormFeedback>
                      {formik.errors["lrDocumentFile_" + i]}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </FormGroup>
          </div>
        </div>
      )
    }
    return legalRepSections
  }

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(`${"Please enter valid email id"}`)
      .required(`${"Email Id is required"}`),
    companyName: yup.string().required(`${"Company name is required."}`),
    incorporationDate: yup
      .date()
      .max(new Date(), "Company incorporation date cannot be in the future")
      .required(`${"Company incorporation date is required"}`)
      .typeError("Invalid date format"),
    taxIdentificationNumber: yup
      .string()
      .required(`${"Tax identification number is required."}`),
    tradeRegister: yup
      .mixed()
      .required(`${"Trade register is required"}`)
      .test(
        "fileFormat",
        "Only PDF or Image file formats are allowed",
        value => {
          if (value) {
            const supportedFormats = ["pdf", "png", "jpg", "jpeg", "svg"]
          }
          return true
        }
      )
      .test("fileSize", "File size must not be more than 5MB", value => {
        if (value) {
          // return value?.size <= 5242880;
        }
        return true
      }),
    authorizedSignatories: yup
      .mixed()
      .required(`${"Authorized signatories is required"}`)
      .test(
        "fileFormat",
        "Only PDF or Image file formats are allowed",
        value => {
          if (value) {
            const supportedFormats = ["pdf", "png", "jpg", "jpeg", "svg"]
          }
          return true
        }
      )
      .test("fileSize", "File size must not be more than 5MB", value => {
        if (value) {
          // return value?.size <= 5242880;
        }
        return true
      }),
    articlesOfAssociation: yup
      .mixed()
      .required(`${"Article of association is required"}`)
      .test(
        "fileFormat",
        "Only PDF or Image file formats are allowed",
        value => {
          if (value) {
            const supportedFormats = ["pdf", "png", "jpg", "jpeg", "svg"]
          }
          return true
        }
      )
      .test("fileSize", "File size must not be more than 5MB", value => {
        if (value) {
          // return value?.size <= 5242880;
        }
        return true
      }),
    financialStatements: yup
      .mixed()
      .required(`${"Financial statement is required"}`)
      .test(
        "fileFormat",
        "Only PDF or Image file formats are allowed",
        value => {
          if (value) {
            const supportedFormats = ["pdf", "png", "jpg", "jpeg", "svg"]
          }
          return true
        }
      )
      .test("fileSize", "File size must not be more than 5MB", value => {
        if (value) {
          // return value?.size <= 5242880;
        }
        return true
      }),
    financialStatements1: yup
      .mixed()
      .required(`${"Financial statement is required"}`)
      .test(
        "fileFormat",
        "Only PDF or Image file formats are allowed",
        value => {
          if (value) {
            const supportedFormats = ["pdf", "png", "jpg", "jpeg", "svg"]
          }
          return true
        }
      )
      .test("fileSize", "File size must not be more than 5MB", value => {
        if (value) {
          // return value?.size <= 5242880;
        }
        return true
      }),
    contactFirstName: yup
      .string()
      .required(`${"Point of contact first name required."}`),
    contactLastName: yup
      .string()
      .required(`${"Point of contact last name required."}`),
    contactEmail: yup
      .string()
      .email(`${"Please enter valid point of contact email id"}`)
      .required(`${"Point of contact email id is required"}`),
    contactCountrySelect: yup
      .string()
      .transform((value, originalValue) => {
        // Extract the value from the object if necessary
        if (typeof originalValue === "object" && originalValue !== null) {
          return originalValue.value || ""
        }
        return originalValue
      })
      .required("Country is required"),
    contactCountryCodeSelect: yup
      .object()
      .shape({
        value: yup.string().required("Country code is required"),
      })
      .required("Country code is required"),
    contactNumber: yup.number().required(`${"Phone number is required"}`),

    ...(uboCount >= 1 && {
      uboFname_1: yup.string().required(`${"First name required."}`),
      uboLname_1: yup.string().required(`${"Last name required."}`),
      uboCountryCode_1: yup
        .object()
        .shape({
          value: yup.string().required("Country code is required"),
        })
        .required("Country code is required"),
      uboPhn_1: yup.number().required(`${"Phone number is required"}`),
      ...((uboIdProofs[0].toLowerCase() === "passport" ||
        uboIdProofs[0].toLowerCase() === "passaporto") && {
        uboPassportNum_1: yup
          .string()
          .required(`${"Passport number is required"}`),
        uboExpiryDatePassport_1: yup
          .date()
          .min(new Date(), "Passport Expiry date cannot be in the past")
          .required(`${"Passport Expiry date is required"}`)
          .typeError("Invalid date format"),
        uboPassportFile_1: yup
          .mixed()
          .required(`${"Passport file is required"}`)
          .test(
            "fileFormat",
            "Only PDF or Image file formats are allowed",
            value => {
              if (value) {
                const supportedFormats = ["pdf", "png", "jpg", "jpeg", "svg"]
                // console.log('value', value);
                // return supportedFormats.includes(value?.name.split('.').pop());
              }
              return true
            }
          )
          .test("fileSize", "File size must not be more than 5MB", value => {
            if (value) {
              // return value?.size <= 5242880;
            }
            return true
          }),
      }),
      ...(uboIdProofs[0].toLowerCase() === "identitycard" ||
        (uboIdProofs[0].toLowerCase() === "carta d'identità" && {
          uboIdNum_1: yup.string().required(`${"Identity Card is required"}`),
          uboExpiryDateIDProof_1: yup
            .date()
            .min(new Date(), "Identity card date cannot be in the past")
            .required(`${"Identity card date is required"}`)
            .typeError("Invalid date format"),
          uboIdFile_1: yup
            .mixed()
            .required(`${"Identity card is required"}`)
            .test(
              "fileFormat",
              "Only PDF or Image file formats are allowed",
              value => {
                if (value) {
                  const supportedFormats = ["pdf", "png", "jpg", "jpeg", "svg"]
                  // console.log('value', value);
                  // return supportedFormats.includes(value?.name.split('.').pop());
                }
                return true
              }
            )
            .test("fileSize", "File size must not be more than 5MB", value => {
              if (value) {
                // return value?.size <= 5242880;
              }
              return true
            }),
        })),
      // Residence proof validation schema
      uboZipcode_1: yup.string().required(`${"Zip code required"}`),
      uboCountry_1: yup
        .string()
        .transform((value, originalValue) => {
          // Extract the value from the object if necessary
          if (typeof originalValue === "object" && originalValue !== null) {
            return originalValue.value || ""
          }
          return originalValue
        })
        .required("Country is required"),
      uboProvince_1: yup.string().required(`${"Please enter province"}`),
      uboRegion_1: yup.string().required(`${"Please enter region"}`),
      uboCity_1: yup.string().required(`${"Please enter city"}`),
      uboStreetArea_1: yup
        .string()
        .required(`${"Please enter street/locality/area"}`),
      uboHouseNo_1: yup.string().required(`${"Please enter house number"}`),
      uboDocumentType_1: yup
        .string()
        .transform((value, originalValue) => {
          // Extract the value from the object if necessary
          if (typeof originalValue === "object" && originalValue !== null) {
            return originalValue.value || ""
          }
          return originalValue
        })
        .required("Document type is required"),
      uboDocumentDate_1: yup
        .date()
        .max(new Date(), "Document date cannot be in the future")
        .required(`${"Document date is required"}`)
        .typeError("Invalid date format"),
      uboDocumentFile_1: yup
        .mixed()
        .required(`${"Document is required"}`)
        .test(
          "fileFormat",
          "Only PDF or Image file formats are allowed",
          value => {
            if (value) {
              const supportedFormats = ["pdf", "png", "jpg", "jpeg", "svg"]
              // return supportedFormats.includes(value?.name.split('.').pop());
            }
            return true
          }
        )
        .test("fileSize", "File size must not be more than 5MB", value => {
          if (value) {
            // return value?.size <= 5242880;
          }
          return true
        }),
      uboShareHoldingRegister_1: yup
        .mixed()
        .required(`${"Share holding register file is required"}`)
        .test(
          "fileFormat",
          "Only PDF or Image file formats are allowed",
          value => {
            if (value) {
              const supportedFormats = ["pdf", "png", "jpg", "jpeg", "svg"]
              // console.log('value', value);
              // return supportedFormats.includes(value?.name.split('.').pop());
            }
            return true
          }
        )
        .test("fileSize", "File size must not be more than 5MB", value => {
          if (value) {
            // return value?.size <= 5242880;
          }
          return true
        }),
    }),
    ...(uboCount >= 2 && {
      uboFname_2: yup.string().required(`${"First name required."}`),
      uboLname_2: yup.string().required(`${"Last name required."}`),
      uboCountryCode_2: yup
        .object()
        .shape({
          value: yup.string().required("Country code is required"),
        })
        .required("Country code is required"),
      uboPhn_2: yup.number().required(`${"Phone number is required"}`),
      ...((uboIdProofs[1].toLowerCase() === "passport" ||
        uboIdProofs[1].toLowerCase() === "passaporto") && {
        uboPassportNum_2: yup
          .string()
          .required(`${"Passport number is required"}`),
        uboExpiryDatePassport_2: yup
          .date()
          .min(new Date(), "Passport Expiry date cannot be in the past")
          .required(`${"Passport Expiry date is required"}`)
          .typeError("Invalid date format"),
        uboPassportFile_2: yup
          .mixed()
          .required(`${"Passport file is required"}`)
          .test(
            "fileFormat",
            "Only PDF or Image file formats are allowed",
            value => {
              if (value) {
                const supportedFormats = ["pdf", "png", "jpg", "jpeg", "svg"]
                // console.log('value', value);
                // return supportedFormats.includes(value?.name.split('.').pop());
              }
              return true
            }
          )
          .test("fileSize", "File size must not be more than 5MB", value => {
            if (value) {
              // return value?.size <= 5242880;
            }
            return true
          }),
      }),
      ...(uboIdProofs[1].toLowerCase() === "identitycard" ||
        (uboIdProofs[1].toLowerCase() === "carta d'identità" && {
          uboIdNum_2: yup.string().required(`${"Identity Card is required"}`),
          uboExpiryDateIDProof_2: yup
            .date()
            .min(new Date(), "Identity card date cannot be in the past")
            .required(`${"Identity card date is required"}`)
            .typeError("Invalid date format"),
          uboIdFile_2: yup
            .mixed()
            .required(`${"Identity card is required"}`)
            .test(
              "fileFormat",
              "Only PDF or Image file formats are allowed",
              value => {
                if (value) {
                  const supportedFormats = ["pdf", "png", "jpg", "jpeg", "svg"]
                  // console.log('value', value);
                  // return supportedFormats.includes(value?.name.split('.').pop());
                }
                return true
              }
            )
            .test("fileSize", "File size must not be more than 5MB", value => {
              if (value) {
                // return value?.size <= 5242880;
              }
              return true
            }),
        })),
      // Residence proof validation schema
      uboZipcode_2: yup.string().required(`${"Zip code required"}`),
      uboCountry_2: yup
        .string()
        .transform((value, originalValue) => {
          // Extract the value from the object if necessary
          if (typeof originalValue === "object" && originalValue !== null) {
            return originalValue.value || ""
          }
          return originalValue
        })
        .required("Country is required"),
      uboProvince_2: yup.string().required(`${"Please enter province"}`),
      uboRegion_2: yup.string().required(`${"Please enter region"}`),
      uboCity_2: yup.string().required(`${"Please enter city"}`),
      uboStreetArea_2: yup
        .string()
        .required(`${"Please enter street/locality/area"}`),
      uboHouseNo_2: yup.string().required(`${"Please enter house number"}`),
      uboDocumentType_2: yup
        .string()
        .transform((value, originalValue) => {
          // Extract the value from the object if necessary
          if (typeof originalValue === "object" && originalValue !== null) {
            return originalValue.value || ""
          }
          return originalValue
        })
        .required("Document type is required"),
      uboDocumentDate_2: yup
        .date()
        .max(new Date(), "Document date cannot be in the future")
        .required(`${"Document date is required"}`)
        .typeError("Invalid date format"),
      uboDocumentFile_2: yup
        .mixed()
        .required(`${"Document is required"}`)
        .test(
          "fileFormat",
          "Only PDF or Image file formats are allowed",
          value => {
            if (value) {
              const supportedFormats = ["pdf", "png", "jpg", "jpeg", "svg"]
              // return supportedFormats.includes(value?.name.split('.').pop());
            }
            return true
          }
        )
        .test("fileSize", "File size must not be more than 5MB", value => {
          if (value) {
            // return value?.size <= 5242880;
          }
          return true
        }),
      uboShareHoldingRegister_2: yup
        .mixed()
        .required(`${"Share holding register file is required"}`)
        .test(
          "fileFormat",
          "Only PDF or Image file formats are allowed",
          value => {
            if (value) {
              const supportedFormats = ["pdf", "png", "jpg", "jpeg", "svg"]
              // console.log('value', value);
              // return supportedFormats.includes(value?.name.split('.').pop());
            }
            return true
          }
        )
        .test("fileSize", "File size must not be more than 5MB", value => {
          if (value) {
            // return value?.size <= 5242880;
          }
          return true
        }),
    }),
    ...(uboCount === 3 && {
      uboFname_3: yup.string().required(`${"First name required."}`),
      uboLname_3: yup.string().required(`${"Last name required."}`),
      uboCountryCode_3: yup
        .object()
        .shape({
          value: yup.string().required("Country code is required"),
        })
        .required("Country code is required"),
      uboPhn_3: yup.number().required(`${"Phone number is required"}`),
      ...((uboIdProofs[2].toLowerCase() === "passport" ||
        uboIdProofs[2].toLowerCase() === "passaporto") && {
        uboPassportNum_3: yup
          .string()
          .required(`${"Passport number is required"}`),
        uboExpiryDatePassport_3: yup
          .date()
          .min(new Date(), "Passport Expiry date cannot be in the past")
          .required(`${"Passport Expiry date is required"}`)
          .typeError("Invalid date format"),
        uboPassportFile_3: yup
          .mixed()
          .required(`${"Passport file is required"}`)
          .test(
            "fileFormat",
            "Only PDF or Image file formats are allowed",
            value => {
              if (value) {
                const supportedFormats = ["pdf", "png", "jpg", "jpeg", "svg"]
                // console.log('value', value);
                // return supportedFormats.includes(value?.name.split('.').pop());
              }
              return true
            }
          )
          .test("fileSize", "File size must not be more than 5MB", value => {
            if (value) {
              // return value?.size <= 5242880;
            }
            return true
          }),
      }),
      ...(uboIdProofs[2].toLowerCase() === "identitycard" ||
        (uboIdProofs[2].toLowerCase() === "carta d'identità" && {
          uboIdNum_3: yup.string().required(`${"Identity Card is required"}`),
          uboExpiryDateIDProof_3: yup
            .date()
            .min(new Date(), "Identity card date cannot be in the past")
            .required(`${"Identity card date is required"}`)
            .typeError("Invalid date format"),
          uboIdFile_3: yup
            .mixed()
            .required(`${"Identity card is required"}`)
            .test(
              "fileFormat",
              "Only PDF or Image file formats are allowed",
              value => {
                if (value) {
                  const supportedFormats = ["pdf", "png", "jpg", "jpeg", "svg"]
                  // console.log('value', value);
                  // return supportedFormats.includes(value?.name.split('.').pop());
                }
                return true
              }
            )
            .test("fileSize", "File size must not be more than 5MB", value => {
              if (value) {
                // return value?.size <= 5242880;
              }
              return true
            }),
        })),
      // Residence proof validation schema
      uboZipcode_3: yup.string().required(`${"Zip code required"}`),
      uboCountry_3: yup
        .string()
        .transform((value, originalValue) => {
          // Extract the value from the object if necessary
          if (typeof originalValue === "object" && originalValue !== null) {
            return originalValue.value || ""
          }
          return originalValue
        })
        .required("Country is required"),
      uboProvince_3: yup.string().required(`${"Please enter province"}`),
      uboRegion_3: yup.string().required(`${"Please enter region"}`),
      uboCity_3: yup.string().required(`${"Please enter city"}`),
      uboStreetArea_3: yup
        .string()
        .required(`${"Please enter street/locality/area"}`),
      uboHouseNo_3: yup.string().required(`${"Please enter house number"}`),
      uboDocumentType_3: yup
        .string()
        .transform((value, originalValue) => {
          // Extract the value from the object if necessary
          if (typeof originalValue === "object" && originalValue !== null) {
            return originalValue.value || ""
          }
          return originalValue
        })
        .required("Document type is required"),
      uboDocumentDate_3: yup
        .date()
        .max(new Date(), "Document date cannot be in the future")
        .required(`${"Document date is required"}`)
        .typeError("Invalid date format"),
      uboDocumentFile_3: yup
        .mixed()
        .required(`${"Document is required"}`)
        .test(
          "fileFormat",
          "Only PDF or Image file formats are allowed",
          value => {
            if (value) {
              const supportedFormats = ["pdf", "png", "jpg", "jpeg", "svg"]
              // return supportedFormats.includes(value?.name.split('.').pop());
            }
            return true
          }
        )
        .test("fileSize", "File size must not be more than 5MB", value => {
          if (value) {
            // return value?.size <= 5242880;
          }
          return true
        }),
      uboShareHoldingRegister_3: yup
        .mixed()
        .required(`${"Share holding register file is required"}`)
        .test(
          "fileFormat",
          "Only PDF or Image file formats are allowed",
          value => {
            if (value) {
              const supportedFormats = ["pdf", "png", "jpg", "jpeg", "svg"]
              // console.log('value', value);
              // return supportedFormats.includes(value?.name.split('.').pop());
            }
            return true
          }
        )
        .test("fileSize", "File size must not be more than 5MB", value => {
          if (value) {
            // return value?.size <= 5242880;
          }
          return true
        }),
    }),

    ...(legalRepCount >= 1 && {
      lrFname_1: yup.string().required(`${"First name required."}`),
      lrLname_1: yup.string().required(`${"Last name required."}`),
      lrCountryCode_1: yup
        .object()
        .shape({
          value: yup.string().required("Country code is required"),
        })
        .required("Country code is required"),
      lrPhn_1: yup.number().required(`${"Phone number is required"}`),
      ...((lrIdProofs[0].toLowerCase() === "passport" ||
        lrIdProofs[0].toLowerCase() === "passaporto") && {
        lrPassportNum_1: yup
          .string()
          .required(`${"Passport number is required"}`),
        lrExpiryDatePassport_1: yup
          .date()
          .min(new Date(), "Passport Expiry date cannot be in the past")
          .required(`${"Passport Expiry date is required"}`)
          .typeError("Invalid date format"),
        lrPassportFile_1: yup
          .mixed()
          .required(`${"Passport file is required"}`)
          .test(
            "fileFormat",
            "Only PDF or Image file formats are allowed",
            value => {
              if (value) {
                const supportedFormats = ["pdf", "png", "jpg", "jpeg", "svg"]
                // console.log('value', value);
                // return supportedFormats.includes(value?.name.split('.').pop());
              }
              return true
            }
          )
          .test("fileSize", "File size must not be more than 5MB", value => {
            if (value) {
              // return value?.size <= 5242880;
            }
            return true
          }),
      }),
      ...(lrIdProofs[0].toLowerCase() === "identitycard" ||
        (lrIdProofs[0].toLowerCase() === "carta d'identità" && {
          lrIdNum_1: yup.string().required(`${"Identity Card is required"}`),
          lrExpiryDateIDProof_1: yup
            .date()
            .min(new Date(), "Identity card date cannot be in the past")
            .required(`${"Identity card date is required"}`)
            .typeError("Invalid date format"),
          lrIdFile_1: yup
            .mixed()
            .required(`${"Identity card is required"}`)
            .test(
              "fileFormat",
              "Only PDF or Image file formats are allowed",
              value => {
                if (value) {
                  const supportedFormats = ["pdf", "png", "jpg", "jpeg", "svg"]
                  // console.log('value', value);
                  // return supportedFormats.includes(value?.name.split('.').pop());
                }
                return true
              }
            )
            .test("fileSize", "File size must not be more than 5MB", value => {
              if (value) {
                // return value?.size <= 5242880;
              }
              return true
            }),
        })),
      // Residence proof validation schema
      lrZipcode_1: yup.string().required(`${"Zip code required"}`),
      lrCountry_1: yup
        .string()
        .transform((value, originalValue) => {
          // Extract the value from the object if necessary
          if (typeof originalValue === "object" && originalValue !== null) {
            return originalValue.value || ""
          }
          return originalValue
        })
        .required("Country is required"),
      lrProvince_1: yup.string().required(`${"Please enter province"}`),
      lrRegion_1: yup.string().required(`${"Please enter region"}`),
      lrCity_1: yup.string().required(`${"Please enter city"}`),
      lrStreetArea_1: yup
        .string()
        .required(`${"Please enter street/locality/area"}`),
      lrHouseNo_1: yup.string().required(`${"Please enter house number"}`),
      lrDocumentType_1: yup
        .string()
        .transform((value, originalValue) => {
          // Extract the value from the object if necessary
          if (typeof originalValue === "object" && originalValue !== null) {
            return originalValue.value || ""
          }
          return originalValue
        })
        .required("Document type is required"),
      lrDocumentDate_1: yup
        .date()
        .max(new Date(), "Document date cannot be in the future")
        .required(`${"Document date is required"}`)
        .typeError("Invalid date format"),
      lrDocumentFile_1: yup
        .mixed()
        .required(`${"Document is required"}`)
        .test(
          "fileFormat",
          "Only PDF or Image file formats are allowed",
          value => {
            if (value) {
              const supportedFormats = ["pdf", "png", "jpg", "jpeg", "svg"]
              // return supportedFormats.includes(value?.name.split('.').pop());
            }
            return true
          }
        )
        .test("fileSize", "File size must not be more than 5MB", value => {
          if (value) {
            // return value?.size <= 5242880;
          }
          return true
        }),
    }),
    ...(legalRepCount >= 2 && {
      lrFname_2: yup.string().required(`${"First name required."}`),
      lrLname_2: yup.string().required(`${"Last name required."}`),
      lrCountryCode_2: yup
        .object()
        .shape({
          value: yup.string().required("Country code is required"),
        })
        .required("Country code is required"),
      lrPhn_2: yup.number().required(`${"Phone number is required"}`),
      ...((lrIdProofs[1].toLowerCase() === "passport" ||
        lrIdProofs[1].toLowerCase() === "passaporto") && {
        lrPassportNum_2: yup
          .string()
          .required(`${"Passport number is required"}`),
        lrExpiryDatePassport_2: yup
          .date()
          .min(new Date(), "Passport Expiry date cannot be in the past")
          .required(`${"Passport Expiry date is required"}`)
          .typeError("Invalid date format"),
        lrPassportFile_2: yup
          .mixed()
          .required(`${"Passport file is required"}`)
          .test(
            "fileFormat",
            "Only PDF or Image file formats are allowed",
            value => {
              if (value) {
                const supportedFormats = ["pdf", "png", "jpg", "jpeg", "svg"]
                // console.log('value', value);
                // return supportedFormats.includes(value?.name.split('.').pop());
              }
              return true
            }
          )
          .test("fileSize", "File size must not be more than 5MB", value => {
            if (value) {
              // return value?.size <= 5242880;
            }
            return true
          }),
      }),
      ...(lrIdProofs[1].toLowerCase() === "identitycard" ||
        (lrIdProofs[1].toLowerCase() === "carta d'identità" && {
          lrIdNum_2: yup.string().required(`${"Identity Card is required"}`),
          lrExpiryDateIDProof_2: yup
            .date()
            .min(new Date(), "Identity card date cannot be in the past")
            .required(`${"Identity card date is required"}`)
            .typeError("Invalid date format"),
          lrIdFile_2: yup
            .mixed()
            .required(`${"Identity card is required"}`)
            .test(
              "fileFormat",
              "Only PDF or Image file formats are allowed",
              value => {
                if (value) {
                  const supportedFormats = ["pdf", "png", "jpg", "jpeg", "svg"]
                  // console.log('value', value);
                  // return supportedFormats.includes(value?.name.split('.').pop());
                }
                return true
              }
            )
            .test("fileSize", "File size must not be more than 5MB", value => {
              if (value) {
                // return value?.size <= 5242880;
              }
              return true
            }),
        })),
      // Residence proof validation schema
      lrZipcode_2: yup.string().required(`${"Zip code required"}`),
      lrCountry_2: yup
        .string()
        .transform((value, originalValue) => {
          // Extract the value from the object if necessary
          if (typeof originalValue === "object" && originalValue !== null) {
            return originalValue.value || ""
          }
          return originalValue
        })
        .required("Country is required"),
      lrProvince_2: yup.string().required(`${"Please enter province"}`),
      lrRegion_2: yup.string().required(`${"Please enter region"}`),
      lrCity_2: yup.string().required(`${"Please enter city"}`),
      lrStreetArea_2: yup
        .string()
        .required(`${"Please enter street/locality/area"}`),
      lrHouseNo_2: yup.string().required(`${"Please enter house number"}`),
      lrDocumentType_2: yup
        .string()
        .transform((value, originalValue) => {
          // Extract the value from the object if necessary
          if (typeof originalValue === "object" && originalValue !== null) {
            return originalValue.value || ""
          }
          return originalValue
        })
        .required("Document type is required"),
      lrDocumentDate_2: yup
        .date()
        .max(new Date(), "Document date cannot be in the future")
        .required(`${"Document date is required"}`)
        .typeError("Invalid date format"),
      lrDocumentFile_2: yup
        .mixed()
        .required(`${"Document is required"}`)
        .test(
          "fileFormat",
          "Only PDF or Image file formats are allowed",
          value => {
            if (value) {
              const supportedFormats = ["pdf", "png", "jpg", "jpeg", "svg"]
              // return supportedFormats.includes(value?.name.split('.').pop());
            }
            return true
          }
        )
        .test("fileSize", "File size must not be more than 5MB", value => {
          if (value) {
            // return value?.size <= 5242880;
          }
          return true
        }),
    }),
    ...(legalRepCount === 3 && {
      lrFname_3: yup.string().required(`${"First name required."}`),
      lrLname_3: yup.string().required(`${"Last name required."}`),
      lrCountryCode_3: yup
        .object()
        .shape({
          value: yup.string().required("Country code is required"),
        })
        .required("Country code is required"),
      lrPhn_3: yup.number().required(`${"Phone number is required"}`),
      ...((lrIdProofs[2].toLowerCase() === "passport" ||
        lrIdProofs[2].toLowerCase() === "passaporto") && {
        lrPassportNum_3: yup
          .string()
          .required(`${"Passport number is required"}`),
        lrExpiryDatePassport_3: yup
          .date()
          .min(new Date(), "Passport Expiry date cannot be in the past")
          .required(`${"Passport Expiry date is required"}`)
          .typeError("Invalid date format"),
        lrPassportFile_3: yup
          .mixed()
          .required(`${"Passport file is required"}`)
          .test(
            "fileFormat",
            "Only PDF or Image file formats are allowed",
            value => {
              if (value) {
                const supportedFormats = ["pdf", "png", "jpg", "jpeg", "svg"]
                // console.log('value', value);
                // return supportedFormats.includes(value?.name.split('.').pop());
              }
              return true
            }
          )
          .test("fileSize", "File size must not be more than 5MB", value => {
            if (value) {
              // return value?.size <= 5242880;
            }
            return true
          }),
      }),
      ...(lrIdProofs[2].toLowerCase() === "identitycard" ||
        (lrIdProofs[2].toLowerCase() === "carta d'identità" && {
          lrIdNum_3: yup.string().required(`${"Identity Card is required"}`),
          lrExpiryDateIDProof_3: yup
            .date()
            .min(new Date(), "Identity card date cannot be in the past")
            .required(`${"Identity card date is required"}`)
            .typeError("Invalid date format"),
          lrIdFile_3: yup
            .mixed()
            .required(`${"Identity card is required"}`)
            .test(
              "fileFormat",
              "Only PDF or Image file formats are allowed",
              value => {
                if (value) {
                  const supportedFormats = ["pdf", "png", "jpg", "jpeg", "svg"]
                  // console.log('value', value);
                  // return supportedFormats.includes(value?.name.split('.').pop());
                }
                return true
              }
            )
            .test("fileSize", "File size must not be more than 5MB", value => {
              if (value) {
                // return value?.size <= 5242880;
              }
              return true
            }),
        })),
      // Residence proof validation schema
      lrZipcode_3: yup.string().required(`${"Zip code required"}`),
      lrCountry_3: yup
        .string()
        .transform((value, originalValue) => {
          // Extract the value from the object if necessary
          if (typeof originalValue === "object" && originalValue !== null) {
            return originalValue.value || ""
          }
          return originalValue
        })
        .required("Country is required"),
      lrProvince_3: yup.string().required(`${"Please enter province"}`),
      lrRegion_3: yup.string().required(`${"Please enter region"}`),
      lrCity_3: yup.string().required(`${"Please enter city"}`),
      lrStreetArea_3: yup
        .string()
        .required(`${"Please enter street/locality/area"}`),
      lrHouseNo_3: yup.string().required(`${"Please enter house number"}`),
      lrDocumentType_3: yup
        .string()
        .transform((value, originalValue) => {
          // Extract the value from the object if necessary
          if (typeof originalValue === "object" && originalValue !== null) {
            return originalValue.value || ""
          }
          return originalValue
        })
        .required("Document type is required"),
      lrDocumentDate_3: yup
        .date()
        .max(new Date(), "Document date cannot be in the future")
        .required(`${"Document date is required"}`)
        .typeError("Invalid date format"),
      lrDocumentFile_3: yup
        .mixed()
        .required(`${"Document is required"}`)
        .test(
          "fileFormat",
          "Only PDF or Image file formats are allowed",
          value => {
            if (value) {
              const supportedFormats = ["pdf", "png", "jpg", "jpeg", "svg"]
              // return supportedFormats.includes(value?.name.split('.').pop());
            }
            return true
          }
        )
        .test("fileSize", "File size must not be more than 5MB", value => {
          if (value) {
            // return value?.size <= 5242880;
          }
          return true
        }),
    }),
  })

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      email: "",
      companyName: "",
      incorporationDate: "",
      taxIdentificationNumber: "",
      tradeRegister: "",
      authorizedSignatories: "",
      articlesOfAssociation: "",
      financialStatements: "",
      financialStatements1: "",
      contactFirstName: "",
      contactLastName: "",
      contactEmail: "",
      contactCountrySelect: "",
      contactCountryCodeSelect: { value: "+91", label: `India (+91)` },
      contactNumber: "",
      uboSelect: { value: "1", label: "1" },
      legalSelect: { value: "1", label: "1" },
      // ubo no 1
      uboId_1: "",
      uboFname_1: "",
      uboLname_1: "",
      uboCountryCode_1: { value: "+91", label: `India (+91)` },
      uboPhn_1: "",
      uboPassportNum_1: "",
      uboExpiryDatePassport_1: "",
      uboPassportFile_1: "",
      uboIdNum_1: "",
      uboExpiryDateIDProof_1: "",
      uboIdFile_1: "",
      uboZipcode_1: "",
      uboCountry_1: "",
      uboProvince_1: "",
      uboRegion_1: "",
      uboCity_1: "",
      uboStreetArea_1: "",
      uboHouseNo_1: "",
      uboDocumentType_1: "",
      uboDocumentDate_1: "",
      uboDocumentFile_1: "",
      uboShareHoldingRegister_1: "",

      // ubo no 2
      uboId_2: "",
      uboFname_2: "",
      uboLname_2: "",
      uboCountryCode_2: { value: "+91", label: `India (+91)` },
      uboPhn_2: "",
      uboPassportNum_2: "",
      uboExpiryDatePassport_2: "",
      uboPassportFile_2: "",
      uboIdNum_2: "",
      uboExpiryDateIDProof_2: "",
      uboIdFile_2: "",
      uboZipcode_2: "",
      uboCountry_2: "",
      uboProvince_2: "",
      uboRegion_2: "",
      uboCity_2: "",
      uboStreetArea_2: "",
      uboHouseNo_2: "",
      uboDocumentType_2: "",
      uboDocumentDate_2: "",
      uboDocumentFile_2: "",
      uboShareHoldingRegister_2: "",

      // ubo no 3
      uboId_3: "",
      uboFname_3: "",
      uboLname_3: "",
      uboCountryCode_3: { value: "+91", label: `India (+91)` },
      uboPhn_3: "",
      uboPassportNum_3: "",
      uboExpiryDatePassport_3: "",
      uboPassportFile_3: "",
      uboIdNum_3: "",
      uboExpiryDateIDProof_3: "",
      uboIdFile_3: "",
      uboZipcode_3: "",
      uboCountry_3: "",
      uboProvince_3: "",
      uboRegion_3: "",
      uboCity_3: "",
      uboStreetArea_3: "",
      uboHouseNo_3: "",
      uboDocumentType_3: "",
      uboDocumentDate_3: "",
      uboDocumentFile_3: "",
      uboShareHoldingRegister_3: "",

      // Lr no 1
      lrId_1: "",
      lrFname_1: "",
      lrLname_1: "",
      lrCountryCode_1: { value: "+91", label: `India (+91)` },
      lrPhn_1: "",
      lrPassportNum_1: "",
      lrExpiryDatePassport_1: "",
      lrPassportFile_1: "",
      lrIdNum_1: "",
      lrExpiryDateIDProof_1: "",
      lrIdFile_1: "",
      lrZipcode_1: "",
      lrCountry_1: "",
      lrProvince_1: "",
      lrRegion_1: "",
      lrCity_1: "",
      lrStreetArea_1: "",
      lrHouseNo_1: "",
      lrDocumentType_1: "",
      lrDocumentDate_1: "",
      lrDocumentFile_1: "",

      // Lr no 2
      lrId_2: "",
      lrFname_2: "",
      lrLname_2: "",
      lrCountryCode_2: { value: "+91", label: `India (+91)` },
      lrPhn_2: "",
      lrPassportNum_2: "",
      lrExpiryDatePassport_2: "",
      lrPassportFile_2: "",
      lrIdNum_2: "",
      lrExpiryDateIDProof_2: "",
      lrIdFile_2: "",
      lrZipcode_2: "",
      lrCountry_2: "",
      lrProvince_2: "",
      lrRegion_2: "",
      lrCity_2: "",
      lrStreetArea_2: "",
      lrHouseNo_2: "",
      lrDocumentType_2: "",
      lrDocumentDate_2: "",
      lrDocumentFile_2: "",

      // Lr no 3
      lrId_3: "",
      lrFname_3: "",
      lrLname_3: "",
      lrCountryCode_3: { value: "+91", label: `India (+91)` },
      lrPhn_3: "",
      lrPassportNum_3: "",
      lrExpiryDatePassport_3: "",
      lrPassportFile_3: "",
      lrIdNum_3: "",
      lrExpiryDateIDProof_3: "",
      lrIdFile_3: "",
      lrZipcode_3: "",
      lrCountry_3: "",
      lrProvince_3: "",
      lrRegion_3: "",
      lrCity_3: "",
      lrStreetArea_3: "",
      lrHouseNo_3: "",
      lrDocumentType_3: "",
      lrDocumentDate_3: "",
      lrDocumentFile_3: "",
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      console.log("values", values)

      if (!isEdit) {
        payload.userdetail.userlrdetail = []
        payload.email = values?.email
        payload.userdetail.img =
          "https://ucarecdn.com/1ccabd74-6863-4125-808a-b9d1315e272e/user.png"

        pointOfContact.firstname = values?.contactFirstName
        pointOfContact.lastname = values?.contactLastName
        pointOfContact.email = values?.contactEmail
        pointOfContact.contact = values?.contactNumber
        pointOfContact.countrycode = values?.contactCountryCodeSelect?.value
        pointOfContact.country = values?.contactCountrySelect?.value

        companyStructure.companyname = values?.companyName
        companyStructure.companyincorporatedate = values?.incorporationDate
        companyStructure.taxnumber = values?.taxIdentificationNumber
        companyStructure.numberofubo = uboCount
        companyStructure.numberoflr = legalRepCount
        companyStructure.document[0].documenturl =
          tradeRegisterPreview?.uploadedFileData?.fileurl
        companyStructure.document[0].fileType =
          tradeRegisterPreview?.uploadedFileData?.filetype
        companyStructure.document[0].fileName =
          tradeRegisterPreview?.uploadedFileData?.filename
        companyStructure.document[1].documenturl =
          authorizedSignatoriesPreview?.uploadedFileData?.fileurl
        companyStructure.document[1].fileType =
          authorizedSignatoriesPreview?.uploadedFileData?.filetype
        companyStructure.document[1].fileName =
          authorizedSignatoriesPreview?.uploadedFileData?.filename
        companyStructure.document[2].documenturl =
          articlesOfAssociationPreview?.uploadedFileData?.fileurl
        companyStructure.document[2].fileType =
          articlesOfAssociationPreview?.uploadedFileData?.filetype
        companyStructure.document[2].fileName =
          articlesOfAssociationPreview?.uploadedFileData?.filename
        companyStructure.document[3].documenturl =
          financialStatementsPreview?.uploadedFileData?.fileurl
        companyStructure.document[3].fileType =
          financialStatementsPreview?.uploadedFileData?.filetype
        companyStructure.document[3].fileName =
          financialStatementsPreview?.uploadedFileData?.filename
        companyStructure.document[4].documenturl =
          financialStatements1Preview?.uploadedFileData?.fileurl
        companyStructure.document[4].fileType =
          financialStatements1Preview?.uploadedFileData?.filetype
        companyStructure.document[4].fileName =
          financialStatements1Preview?.uploadedFileData?.filename

        for (let i = 1; i <= uboCount; i++) {
          const passportDocDetails =
            i === 1
              ? uboPassportPreview_1
              : i === 2
              ? uboPassportPreview_2
              : uboPassportPreview_3
          const identityCardDocDetails =
            i === 1
              ? uboIdentityCardPreview_1
              : i === 2
              ? uboIdentityCardPreview_2
              : uboIdentityCardPreview_3
          const shareholdingRegisterDocDetails =
            i === 1
              ? shareholdingRegisterPreview_1
              : i === 2
              ? shareholdingRegisterPreview_2
              : shareholdingRegisterPreview_3
          const residenceProofDocDetails =
            i === 1
              ? uboResidenceProofPreview_1
              : i === 2
              ? uboResidenceProofPreview_2
              : uboResidenceProofPreview_3

          console.log(
            "passportDocDetails",
            passportDocDetails,
            uboPassportPreview_1
          )

          let tempUBOStructure = JSON.parse(JSON.stringify(uboStructure))

          tempUBOStructure.firstname = values["uboFname_" + i]
          tempUBOStructure.lastname = values["uboLname_" + i]
          tempUBOStructure.countrycode = values["uboCountryCode_" + i]?.value
          tempUBOStructure.contact = values["uboPhn_" + i]

          tempUBOStructure.document[0].documentsubtype = uboIdProofs[i - 1]
          tempUBOStructure.document[0].documenturl =
            uboIdProofs[i - 1].toLowerCase() === "passport" ||
            uboIdProofs[i - 1].toLowerCase() === "passaporto"
              ? passportDocDetails?.uploadedFileData?.fileurl
              : identityCardDocDetails?.uploadedFileData?.fileurl
          tempUBOStructure.document[0].expirydate =
            uboIdProofs[i - 1].toLowerCase() === "passport" ||
            uboIdProofs[i - 1].toLowerCase() === "passaporto"
              ? values["uboExpiryDatePassport_" + i]
              : values["uboExpiryDateIDProof_" + i]
          tempUBOStructure.document[0].documentnumber =
            uboIdProofs[i - 1].toLowerCase() === "passport" ||
            uboIdProofs[i - 1].toLowerCase() === "passaporto"
              ? values["uboPassportNum_" + i]
              : values["uboIdNum_" + i]
          tempUBOStructure.document[0].filetype =
            uboIdProofs[i - 1].toLowerCase() === "passport" ||
            uboIdProofs[i - 1].toLowerCase() === "passaporto"
              ? passportDocDetails?.uploadedFileData?.filetype
              : identityCardDocDetails?.uploadedFileData?.filetype
          tempUBOStructure.document[0].fileName =
            uboIdProofs[i - 1].toLowerCase() === "passport" ||
            uboIdProofs[i - 1].toLowerCase() === "passaporto"
              ? passportDocDetails?.uploadedFileData?.filename
              : identityCardDocDetails?.uploadedFileData?.filename

          tempUBOStructure.document[1].documenturl =
            residenceProofDocDetails?.uploadedFileData?.fileurl
          tempUBOStructure.document[1].documentsubtype =
            values["uboDocumentType_" + i]?.value
          tempUBOStructure.document[1].expirydate =
            values["uboDocumentDate_" + i]
          tempUBOStructure.document[1].fileType =
            residenceProofDocDetails?.uploadedFileData?.filetype
          tempUBOStructure.document[1].fileName =
            residenceProofDocDetails?.uploadedFileData?.filename
          tempUBOStructure.document[1].country = values["uboCountry_" + i].value
          tempUBOStructure.document[1].provience = values["uboProvince_" + i]
          tempUBOStructure.document[1].zipcode = values["uboZipcode_" + i]
          tempUBOStructure.document[1].city = values["uboCity_" + i]
          tempUBOStructure.document[1].street = values["uboStreetArea_" + i]
          tempUBOStructure.document[1].housenumber = values["uboHouseNo_" + i]
          tempUBOStructure.document[1].region = values["uboRegion_" + i]

          tempUBOStructure.document[2].documenturl =
            shareholdingRegisterDocDetails?.uploadedFileData?.fileurl
          tempUBOStructure.document[2].fileType =
            shareholdingRegisterDocDetails?.uploadedFileData?.filetype
          tempUBOStructure.document[2].fileName =
            shareholdingRegisterDocDetails?.uploadedFileData?.filename

          payload.userdetail.userlrdetail.push(tempUBOStructure)
        }

        for (let i = 1; i <= legalRepCount; i++) {
          const passportDocDetails =
            i === 1
              ? lrPassportPreview_1
              : i === 2
              ? lrPassportPreview_2
              : lrPassportPreview_3
          const identityCardDocDetails =
            i === 1
              ? lrIdentityCardPreview_1
              : i === 2
              ? lrIdentityCardPreview_2
              : lrIdentityCardPreview_3
          const residenceProofDocDetails =
            i === 1
              ? lrResidenceProofPreview_1
              : i === 2
              ? lrResidenceProofPreview_2
              : lrResidenceProofPreview_3

          let tempLRStructure = JSON.parse(JSON.stringify(lrStructure))
          tempLRStructure.firstname = values["lrFname_" + i]
          tempLRStructure.lastname = values["lrLname_" + i]
          tempLRStructure.countrycode = values["lrCountryCode_" + i]?.value
          tempLRStructure.contact = values["lrPhn_" + i]

          tempLRStructure.document[0].documentsubtype = lrIdProofs[i - 1]
          tempLRStructure.document[0].documenturl =
            lrIdProofs[i - 1].toLowerCase() === "passport" ||
            lrIdProofs[i - 1].toLowerCase() === "passaporto"
              ? passportDocDetails?.uploadedFileData?.fileurl
              : identityCardDocDetails?.uploadedFileData?.fileurl
          tempLRStructure.document[0].expirydate =
            lrIdProofs[i - 1].toLowerCase() === "passport" ||
            lrIdProofs[i - 1].toLowerCase() === "passaporto"
              ? values["lrExpiryDatePassport_" + i]
              : values["lrExpiryDateIDProof_" + i]
          tempLRStructure.document[0].documentnumber =
            lrIdProofs[i - 1].toLowerCase() === "passport" ||
            lrIdProofs[i - 1].toLowerCase() === "passaporto"
              ? values["lrPassportNum_" + i]
              : values["lrIdNum_" + i]
          tempLRStructure.document[0].filetype =
            lrIdProofs[i - 1].toLowerCase() === "passport" ||
            lrIdProofs[i - 1].toLowerCase() === "passaporto"
              ? passportDocDetails?.uploadedFileData?.filetype
              : identityCardDocDetails?.uploadedFileData?.filetype
          tempLRStructure.document[0].fileName =
            lrIdProofs[i - 1].toLowerCase() === "passport" ||
            lrIdProofs[i - 1].toLowerCase() === "passaporto"
              ? passportDocDetails?.uploadedFileData?.filename
              : identityCardDocDetails?.uploadedFileData?.filename

          tempLRStructure.document[1].documenturl =
            residenceProofDocDetails?.uploadedFileData?.fileurl
          tempLRStructure.document[1].documentsubtype =
            values["lrDocumentType_" + i]?.value
          tempLRStructure.document[1].expirydate = values["lrDocumentDate_" + i]
          tempLRStructure.document[1].fileType =
            residenceProofDocDetails?.uploadedFileData?.filetype
          tempLRStructure.document[1].fileName =
            residenceProofDocDetails?.uploadedFileData?.filename
          tempLRStructure.document[1].country = values["lrCountry_" + i].value
          tempLRStructure.document[1].provience = values["lrProvince_" + i]
          tempLRStructure.document[1].zipcode = values["lrZipcode_" + i]
          tempLRStructure.document[1].city = values["lrCity_" + i]
          tempLRStructure.document[1].street = values["lrStreetArea_" + i]
          tempLRStructure.document[1].housenumber = values["lrHouseNo_" + i]
          tempLRStructure.document[1].region = values["lrRegion_" + i]

          payload.userdetail.userlrdetail.push(tempLRStructure)
        }

        payload.userdetail.userlrdetail.push(pointOfContact)
        payload.userdetail.userlrdetail.push(companyStructure)

        console.log("payload", payload)
        axios
          .post(
            "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/user/add",
            payload,
            {
              headers: {
                Authorization: `Bearer ${
                  JSON.parse(localStorage.getItem("authUser")).accesstoken
                }`,
                "Content-Type": "application/json",
              },
            }
          )
          .then(response => {
            if (response.data.success) {
              formik.resetForm()
              toast.success("LR user added successfully...!")
              history.push("/users")
            }
          })
          .catch(error => {
            console.error("Error fetching data:", error)
            toast.error(error.response.data.error)
          })
      } else {
        let payload = JSON.parse(JSON.stringify(usersById[0]))
        delete payload?.email
        delete payload?.userdetail?.document
        // payload.status = "approved"
        payload["islr"] = true

        let companyObj = usersById[0]?.userdetail?.userlrdetail.find(item => {
          return item.detailtype === "company"
        })
        let poiObj = usersById[0]?.userdetail?.userlrdetail.find(item => {
          return item.detailtype === "pointofcontact"
        })
        let uboArr = usersById[0]?.userdetail?.userlrdetail.filter(item => {
          return item.detailtype === "ubo"
        })
        let lrArr = usersById[0]?.userdetail?.userlrdetail.filter(item => {
          return item.detailtype === "lr"
        })
        payload.userdetail.userlrdetail = []

        poiObj.firstname = values?.contactFirstName
        poiObj.lastname = values?.contactLastName
        poiObj.email = values?.contactEmail
        poiObj.contact = values?.contactNumber
        poiObj.countrycode = values?.contactCountryCodeSelect?.value
        poiObj.country = values?.contactCountrySelect?.value

        companyObj.companyname = values?.companyName
        companyObj.companyincorporatedate = values?.incorporationDate
        companyObj.taxnumber = values?.taxIdentificationNumber
        companyObj.numberofubo = uboCount
        companyObj.numberoflr = legalRepCount
        if (Object.keys(tradeRegisterPreview.uploadedFileData).length > 0) {
          companyObj.document[0].documenturl =
            tradeRegisterPreview?.uploadedFileData?.fileurl
          companyObj.document[0].fileType =
            tradeRegisterPreview?.uploadedFileData?.filetype
          companyObj.document[0].fileName =
            tradeRegisterPreview?.uploadedFileData?.filename
        }
        if (
          Object.keys(authorizedSignatoriesPreview.uploadedFileData).length > 0
        ) {
          companyObj.document[1].documenturl =
            authorizedSignatoriesPreview?.uploadedFileData?.fileurl
          companyObj.document[1].fileType =
            authorizedSignatoriesPreview?.uploadedFileData?.filetype
          companyObj.document[1].fileName =
            authorizedSignatoriesPreview?.uploadedFileData?.filename
        }
        if (
          Object.keys(articlesOfAssociationPreview.uploadedFileData).length > 0
        ) {
          companyObj.document[2].documenturl =
            articlesOfAssociationPreview?.uploadedFileData?.fileurl
          companyObj.document[2].fileType =
            articlesOfAssociationPreview?.uploadedFileData?.filetype
          companyObj.document[2].fileName =
            articlesOfAssociationPreview?.uploadedFileData?.filename
        }
        if (
          Object.keys(financialStatementsPreview.uploadedFileData).length > 0
        ) {
          companyObj.document[3].documenturl =
            financialStatementsPreview?.uploadedFileData?.fileurl
          companyObj.document[3].fileType =
            financialStatementsPreview?.uploadedFileData?.filetype
          companyObj.document[3].fileName =
            financialStatementsPreview?.uploadedFileData?.filename
        }
        if (
          Object.keys(financialStatements1Preview.uploadedFileData).length > 0
        ) {
          companyObj.document[4].documenturl =
            financialStatements1Preview?.uploadedFileData?.fileurl
          companyObj.document[4].fileType =
            financialStatements1Preview?.uploadedFileData?.filetype
          companyObj.document[4].fileName =
            financialStatements1Preview?.uploadedFileData?.filename
        }

        for (let i = 1; i <= uboCount; i++) {
          const passportDocDetails =
            i === 1
              ? uboPassportPreview_1
              : i === 2
              ? uboPassportPreview_2
              : uboPassportPreview_3
          const identityCardDocDetails =
            i === 1
              ? uboIdentityCardPreview_1
              : i === 2
              ? uboIdentityCardPreview_2
              : uboIdentityCardPreview_3
          const shareholdingRegisterDocDetails =
            i === 1
              ? shareholdingRegisterPreview_1
              : i === 2
              ? shareholdingRegisterPreview_2
              : shareholdingRegisterPreview_3
          const residenceProofDocDetails =
            i === 1
              ? uboResidenceProofPreview_1
              : i === 2
              ? uboResidenceProofPreview_2
              : uboResidenceProofPreview_3

          if (i <= uboArr.length) {
            if (uboArr[i - 1]?.id === values["uboId_" + i]) {
              let uboPayloadObj = JSON.parse(JSON.stringify(uboArr[i - 1]))

              uboPayloadObj.firstname = values["uboFname_" + i]
              uboPayloadObj.lastname = values["uboLname_" + i]
              uboPayloadObj.countrycode = values["uboCountryCode_" + i]?.value
              uboPayloadObj.contact = values["uboPhn_" + i]

              uboPayloadObj.document[0].documentsubtype = uboIdProofs[i - 1]
              uboPayloadObj.document[0].expirydate =
                uboIdProofs[i - 1].toLowerCase() === "passport" || "passaporto"
                  ? values["uboExpiryDatePassport_" + i]
                  : values["uboExpiryDateIDProof_" + i]
              uboPayloadObj.document[0].documentnumber =
                uboIdProofs[i - 1].toLowerCase() === "passport" || "passaporto"
                  ? values["uboPassportNum_" + i]
                  : values["uboIdNum_" + i]

              if (
                uboIdProofs[i - 1].toLowerCase() === "passport" || "passaporto"
                  ? Object.keys(passportDocDetails.uploadedFileData).length > 0
                  : Object.keys(identityCardDocDetails.uploadedFileData)
                      .length > 0
              ) {
                uboPayloadObj.document[0].documenturl =
                  uboIdProofs[i - 1].toLowerCase() === "passport" ||
                  "passaporto"
                    ? passportDocDetails?.uploadedFileData?.fileurl
                    : identityCardDocDetails?.uploadedFileData?.fileurl
                uboPayloadObj.document[0].filetype =
                  uboIdProofs[i - 1].toLowerCase() === "passport" ||
                  "passaporto"
                    ? passportDocDetails?.uploadedFileData?.filetype
                    : identityCardDocDetails?.uploadedFileData?.filetype
                uboPayloadObj.document[0].fileName =
                  uboIdProofs[i - 1].toLowerCase() === "passport" ||
                  "passaporto"
                    ? passportDocDetails?.uploadedFileData?.filename
                    : identityCardDocDetails?.uploadedFileData?.filename
              }

              uboPayloadObj.document[1].documentsubtype =
                values["uboDocumentType_" + i]?.value
              uboPayloadObj.document[1].expirydate =
                values["uboDocumentDate_" + i]

              if (
                Object.keys(residenceProofDocDetails.uploadedFileData).length >
                0
              ) {
                uboPayloadObj.document[1].documenturl =
                  residenceProofDocDetails?.uploadedFileData?.fileurl
                uboPayloadObj.document[1].fileType =
                  residenceProofDocDetails?.uploadedFileData?.filetype
                uboPayloadObj.document[1].fileName =
                  residenceProofDocDetails?.uploadedFileData?.filename
              }
              uboPayloadObj.document[1].country =
                values["uboCountry_" + i].value
              uboPayloadObj.document[1].provience = values["uboProvince_" + i]
              uboPayloadObj.document[1].zipcode = values["uboZipcode_" + i]
              uboPayloadObj.document[1].city = values["uboCity_" + i]
              uboPayloadObj.document[1].street = values["uboStreetArea_" + i]
              uboPayloadObj.document[1].housenumber = values["uboHouseNo_" + i]
              uboPayloadObj.document[1].region = values["uboRegion_" + i]

              if (
                Object.keys(shareholdingRegisterDocDetails.uploadedFileData)
                  .length > 0
              ) {
                uboPayloadObj.document[2].documenturl =
                  shareholdingRegisterDocDetails?.uploadedFileData?.fileurl
                uboPayloadObj.document[2].fileType =
                  shareholdingRegisterDocDetails?.uploadedFileData?.filetype
                uboPayloadObj.document[2].fileName =
                  shareholdingRegisterDocDetails?.uploadedFileData?.filename
              }

              payload.userdetail.userlrdetail.push(uboPayloadObj)
            }
          } else {
            uboStructure["userdetailid"] = usersById[0]?.userdetail?.id
            uboStructure["userid"] = usersById[0]?.userdetail?.userid
            uboStructure.firstname = values["uboFname_" + i]
            uboStructure.lastname = values["uboLname_" + i]
            uboStructure.countrycode = values["uboCountryCode_" + i]?.value
            uboStructure.contact = values["uboPhn_" + i]

            uboStructure.document[0]["userdetailid"] =
              usersById[0]?.userdetail?.id
            uboStructure.document[0]["userid"] =
              usersById[0]?.userdetail?.userid
            uboStructure.document[0].documentsubtype = uboIdProofs[i - 1]
            uboStructure.document[0].documenturl =
              uboIdProofs[i - 1].toLowerCase() === "passport" || "passaporto"
                ? passportDocDetails?.uploadedFileData?.fileurl
                : identityCardDocDetails?.uploadedFileData?.fileurl
            uboStructure.document[0].expirydate =
              uboIdProofs[i - 1].toLowerCase() === "passport" || "passaporto"
                ? values["uboExpiryDatePassport_" + i]
                : values["uboExpiryDateIDProof_" + i]
            uboStructure.document[0].documentnumber =
              uboIdProofs[i - 1].toLowerCase() === "passport" || "passaporto"
                ? values["uboPassportNum_" + i]
                : values["uboIdNum_" + i]
            uboStructure.document[0].filetype =
              uboIdProofs[i - 1].toLowerCase() === "passport" || "passaporto"
                ? passportDocDetails?.uploadedFileData?.filetype
                : identityCardDocDetails?.uploadedFileData?.filetype
            uboStructure.document[0].fileName =
              uboIdProofs[i - 1].toLowerCase() === "passport" || "passaporto"
                ? passportDocDetails?.uploadedFileData?.filename
                : identityCardDocDetails?.uploadedFileData?.filename

            uboStructure.document[1]["userdetailid"] =
              usersById[0]?.userdetail?.id
            uboStructure.document[1]["userid"] =
              usersById[0]?.userdetail?.userid
            uboStructure.document[1].documenturl =
              residenceProofDocDetails?.uploadedFileData?.fileurl
            uboStructure.document[1].documentsubtype =
              values["uboDocumentType_" + i]?.value
            uboStructure.document[1].expirydate = values["uboDocumentDate_" + i]
            uboStructure.document[1].fileType =
              residenceProofDocDetails?.uploadedFileData?.filetype
            uboStructure.document[1].fileName =
              residenceProofDocDetails?.uploadedFileData?.filename
            uboStructure.document[1].country = values["uboCountry_" + i].value
            uboStructure.document[1].provience = values["uboProvince_" + i]
            uboStructure.document[1].zipcode = values["uboZipcode_" + i]
            uboStructure.document[1].city = values["uboCity_" + i]
            uboStructure.document[1].street = values["uboStreetArea_" + i]
            uboStructure.document[1].housenumber = values["uboHouseNo_" + i]
            uboStructure.document[1].region = values["uboRegion_" + i]

            uboStructure.document[2]["userdetailid"] =
              usersById[0]?.userdetail?.id
            uboStructure.document[2]["userid"] =
              usersById[0]?.userdetail?.userid
            uboStructure.document[2].documenturl =
              shareholdingRegisterDocDetails?.uploadedFileData?.fileurl
            uboStructure.document[2].fileType =
              shareholdingRegisterDocDetails?.uploadedFileData?.filetype
            uboStructure.document[2].fileName =
              shareholdingRegisterDocDetails?.uploadedFileData?.filename

            payload.userdetail.userlrdetail.push(uboStructure)
          }
        }

        for (let i = 1; i <= legalRepCount; i++) {
          const passportDocDetails =
            i === 1
              ? lrPassportPreview_1
              : i === 2
              ? lrPassportPreview_2
              : lrPassportPreview_3
          const identityCardDocDetails =
            i === 1
              ? lrIdentityCardPreview_1
              : i === 2
              ? lrIdentityCardPreview_2
              : lrIdentityCardPreview_3
          const residenceProofDocDetails =
            i === 1
              ? lrResidenceProofPreview_1
              : i === 2
              ? lrResidenceProofPreview_2
              : lrResidenceProofPreview_3

          if (i <= lrArr.length) {
            if (lrArr[i - 1]?.id === values["lrId_" + i]) {
              let lrPayloadObj = JSON.parse(JSON.stringify(lrArr[i - 1]))

              lrPayloadObj.firstname = values["lrFname_" + i]
              lrPayloadObj.lastname = values["lrLname_" + i]
              lrPayloadObj.countrycode = values["lrCountryCode_" + i]?.value
              lrPayloadObj.contact = values["lrPhn_" + i]

              lrPayloadObj.document[0].documentsubtype = lrIdProofs[i - 1]
              lrPayloadObj.document[0].expirydate =
                lrIdProofs[i - 1].toLowerCase() === "passport" || "passaporto"
                  ? values["lrExpiryDatePassport_" + i]
                  : values["lrExpiryDateIDProof_" + i]
              lrPayloadObj.document[0].documentnumber =
                lrIdProofs[i - 1].toLowerCase() === "passport" || "passaporto"
                  ? values["lrPassportNum_" + i]
                  : values["lrIdNum_" + i]

              if (
                lrIdProofs[i - 1].toLowerCase() === "passport" || "passaporto"
                  ? Object.keys(passportDocDetails.uploadedFileData).length > 0
                  : Object.keys(identityCardDocDetails.uploadedFileData)
                      .length > 0
              ) {
                lrPayloadObj.document[0].documenturl =
                  lrIdProofs[i - 1].toLowerCase() === "passport" || "passaporto"
                    ? passportDocDetails?.uploadedFileData?.fileurl
                    : identityCardDocDetails?.uploadedFileData?.fileurl
                lrPayloadObj.document[0].filetype =
                  lrIdProofs[i - 1].toLowerCase() === "passport" || "passaporto"
                    ? passportDocDetails?.uploadedFileData?.filetype
                    : identityCardDocDetails?.uploadedFileData?.filetype
                lrPayloadObj.document[0].fileName =
                  lrIdProofs[i - 1].toLowerCase() === "passport" || "passaporto"
                    ? passportDocDetails?.uploadedFileData?.filename
                    : identityCardDocDetails?.uploadedFileData?.filename
              }

              lrPayloadObj.document[1].documentsubtype =
                values["lrDocumentType_" + i]?.value
              lrPayloadObj.document[1].expirydate =
                values["lrDocumentDate_" + i]

              if (
                Object.keys(residenceProofDocDetails.uploadedFileData).length >
                0
              ) {
                lrPayloadObj.document[1].documenturl =
                  residenceProofDocDetails?.uploadedFileData?.fileurl
                lrPayloadObj.document[1].fileType =
                  residenceProofDocDetails?.uploadedFileData?.filetype
                lrPayloadObj.document[1].fileName =
                  residenceProofDocDetails?.uploadedFileData?.filename
              }
              lrPayloadObj.document[1].country = values["lrCountry_" + i].value
              lrPayloadObj.document[1].provience = values["lrProvince_" + i]
              lrPayloadObj.document[1].zipcode = values["lrZipcode_" + i]
              lrPayloadObj.document[1].city = values["lrCity_" + i]
              lrPayloadObj.document[1].street = values["lrStreetArea_" + i]
              lrPayloadObj.document[1].housenumber = values["lrHouseNo_" + i]
              lrPayloadObj.document[1].region = values["lrRegion_" + i]

              payload.userdetail.userlrdetail.push(lrPayloadObj)
            }
          } else {
            lrStructure["userdetailid"] = usersById[0]?.userdetail?.id
            lrStructure["userid"] = usersById[0]?.userdetail?.userid
            lrStructure.firstname = values["lrFname_" + i]
            lrStructure.lastname = values["lrLname_" + i]
            lrStructure.countrycode = values["lrCountryCode_" + i]?.value
            lrStructure.contact = values["lrPhn_" + i]

            lrStructure.document[0]["userdetailid"] =
              usersById[0]?.userdetail?.id
            lrStructure.document[0]["userid"] = usersById[0]?.userdetail?.userid
            lrStructure.document[0].documentsubtype = lrIdProofs[i - 1]
            lrStructure.document[0].documenturl =
              lrIdProofs[i - 1].toLowerCase() === "passport" || "passaporto"
                ? passportDocDetails?.uploadedFileData?.fileurl
                : identityCardDocDetails?.uploadedFileData?.fileurl
            lrStructure.document[0].expirydate =
              lrIdProofs[i - 1].toLowerCase() === "passport" || "passaporto"
                ? values["lrExpiryDatePassport_" + i]
                : values["lrExpiryDateIDProof_" + i]
            lrStructure.document[0].documentnumber =
              lrIdProofs[i - 1].toLowerCase() === "passport" || "passaporto"
                ? values["lrPassportNum_" + i]
                : values["lrIdNum_" + i]
            lrStructure.document[0].filetype =
              lrIdProofs[i - 1].toLowerCase() === "passport" || "passaporto"
                ? passportDocDetails?.uploadedFileData?.filetype
                : identityCardDocDetails?.uploadedFileData?.filetype
            lrStructure.document[0].fileName =
              lrIdProofs[i - 1].toLowerCase() === "passport" || "passaporto"
                ? passportDocDetails?.uploadedFileData?.filename
                : identityCardDocDetails?.uploadedFileData?.filename
            lrStructure.document[0].status = "Approved"
            lrStructure.document[1]["userdetailid"] =
              usersById[0]?.userdetail?.id
            lrStructure.document[1]["userid"] = usersById[0]?.userdetail?.userid
            lrStructure.document[1].documenturl =
              residenceProofDocDetails?.uploadedFileData?.fileurl
            lrStructure.document[1].documentsubtype =
              values["lrDocumentType_" + i]?.value
            lrStructure.document[1].expirydate = values["lrDocumentDate_" + i]
            lrStructure.document[1].fileType =
              residenceProofDocDetails?.uploadedFileData?.filetype
            lrStructure.document[1].fileName =
              residenceProofDocDetails?.uploadedFileData?.filename
            lrStructure.document[1].country = values["lrCountry_" + i].value
            lrStructure.document[1].provience = values["lrProvince_" + i]
            lrStructure.document[1].zipcode = values["lrZipcode_" + i]
            lrStructure.document[1].city = values["lrCity_" + i]
            lrStructure.document[1].street = values["lrStreetArea_" + i]
            lrStructure.document[1].housenumber = values["lrHouseNo_" + i]
            lrStructure.document[1].region = values["lrRegion_" + i]
            lrStructure.document[1].status = "Approved"

            payload.userdetail.userlrdetail.push(lrStructure)
          }
        }

        payload.userdetail.userlrdetail.push(poiObj)
        payload.userdetail.userlrdetail.push(companyObj)

        for (let i = 0; i < payload.userdetail.userlrdetail.length; i++) {}

        console.log("update payload", payload)
        axios
          .put(
            "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/user",
            payload,
            {
              headers: {
                Authorization: `Bearer ${
                  JSON.parse(localStorage.getItem("authUser")).accesstoken
                }`,
                "Content-Type": "application/json",
              },
            }
          )
          .then(response => {
            if (response.data.success) {
              formik.resetForm()
              toast.success("LR details updated successfully...!")
              history.push("/users")
            }
          })
          .catch(error => {
            console.error("Error fetching data:", error)
            toast.error(error.response.data.error)
          })
      }
    },
  })

  // Utility function to get today's date in yyyy-mm-dd format
  const getTodayDate = () => {
    const today = new Date()
    const year = today.getFullYear()
    const month = ("0" + (today.getMonth() + 1)).slice(-2) // Add leading zero
    const day = ("0" + today.getDate()).slice(-2) // Add leading zero
    return `${year}-${month}-${day}`
  }

  // Utility function to get the current financial year
  const getCurrentFinancialYear = () => {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const currentMonth = currentDate.getMonth()

    // Financial year starts in April (month index 3)
    if (currentMonth >= 3) {
      return `${currentYear - 1} - ${currentYear}`
    } else {
      return `${currentYear - 2} - ${currentYear - 1}`
    }
  }

  // Utility function to get the Last financial year
  const getLastFinancialYear = () => {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const currentMonth = currentDate.getMonth()

    // Financial year starts in April (month index 3)
    if (currentMonth >= 3) {
      return `${currentYear - 2} - ${currentYear - 1}`
    } else {
      return `${currentYear - 3} - ${currentYear - 2}`
    }
  }

  const renderPreview = (previewFileObj, previewFileType, previewFileName) => {
    if (!previewFileObj) return null

    return (
      <div>
        {previewFileType.startsWith("image/") ? (
          <img
            src={previewFileObj}
            alt="Preview"
            style={{ width: "50px", maxHeight: "50px", marginTop: "10px" }}
          />
        ) : previewFileType.startsWith("application/pdf") ? (
          <iframe
            src={previewFileObj}
            style={{ width: "50px", maxHeight: "50px", marginTop: "10px" }}
            title="PDF Preview"
          />
        ) : (
          <div>File preview not available for this type</div>
        )}
        <div style={{ marginTop: "5px" }}>{previewFileName}</div>
      </div>
    )
  }

  const handleUpload = (e, fieldRef) => {
    console.log("fieldRef", fieldRef)
    const file = e.target.files[0]
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        toast.error("File size exceeded")
        return
      }
      if (!file.type.includes("image") && !file.type.includes("pdf")) {
        formik.setFieldError(fieldRef, "File format not allowed")
        return
      }
      const formData = new FormData()
      formData.append("file", file)
      formData.append("type", file.type.includes("pdf") ? "document" : "images")

      axios
        .post(
          "https://asiaimpactapi.digitalsalt.in/asiaimpact/v1/documentUpload",
          formData,
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("authUser")).accesstoken
              }`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(res => {
          if (res.data.success) {
            if (file) {
              formik.setFieldValue(fieldRef, e.target.files)
              const reader = new FileReader()
              const fileUrl = URL.createObjectURL(file)

              reader.onloadend = () => {
                if (fieldRef === "tradeRegister") {
                  setTradeRegisterPreview(prevState => ({
                    ...prevState,
                    fileName: res.data.data.filename,
                    fileType: file.type,
                    filePreviewObj: fileUrl,
                    uploadedFileData: res.data.data,
                  }))
                } else if (fieldRef === "authorizedSignatories") {
                  setAuthorizedSignatoriesPreview(prevState => ({
                    ...prevState,
                    fileName: res.data.data.filename,
                    fileType: file.type,
                    filePreviewObj: fileUrl,
                    uploadedFileData: res.data.data,
                  }))
                } else if (fieldRef === "articlesOfAssociation") {
                  setArticlesOfAssociationPreview(prevState => ({
                    ...prevState,
                    fileName: res.data.data.filename,
                    fileType: file.type,
                    filePreviewObj: fileUrl,
                    uploadedFileData: res.data.data,
                  }))
                } else if (fieldRef === "financialStatements") {
                  setFinancialStatementsPreview(prevState => ({
                    ...prevState,
                    fileName: res.data.data.filename,
                    fileType: file.type,
                    filePreviewObj: fileUrl,
                    uploadedFileData: res.data.data,
                  }))
                } else if (fieldRef === "financialStatements1") {
                  setFinancialStatements1Preview(prevState => ({
                    ...prevState,
                    fileName: res.data.data.filename,
                    fileType: file.type,
                    filePreviewObj: fileUrl,
                    uploadedFileData: res.data.data,
                  }))
                } else if (fieldRef === "uboPassportFile_1") {
                  setUboPassportPreview_1(prevState => ({
                    ...prevState,
                    fileName: res.data.data.filename,
                    fileType: file.type,
                    filePreviewObj: fileUrl,
                    uploadedFileData: res.data.data,
                  }))
                } else if (fieldRef === "uboPassportFile_2") {
                  setUboPassportPreview_2(prevState => ({
                    ...prevState,
                    fileName: res.data.data.filename,
                    fileType: file.type,
                    filePreviewObj: fileUrl,
                    uploadedFileData: res.data.data,
                  }))
                } else if (fieldRef === "uboPassportFile_3") {
                  setUboPassportPreview_3(prevState => ({
                    ...prevState,
                    fileName: res.data.data.filename,
                    fileType: file.type,
                    filePreviewObj: fileUrl,
                    uploadedFileData: res.data.data,
                  }))
                } else if (fieldRef === "uboIdFile_1") {
                  setUboIdentityCardPreview_1(prevState => ({
                    ...prevState,
                    fileName: res.data.data.filename,
                    fileType: file.type,
                    filePreviewObj: fileUrl,
                    uploadedFileData: res.data.data,
                  }))
                } else if (fieldRef === "uboIdFile_2") {
                  setUboIdentityCardPreview_2(prevState => ({
                    ...prevState,
                    fileName: res.data.data.filename,
                    fileType: file.type,
                    filePreviewObj: fileUrl,
                    uploadedFileData: res.data.data,
                  }))
                } else if (fieldRef === "uboIdFile_3") {
                  setUboIdentityCardPreview_3(prevState => ({
                    ...prevState,
                    fileName: res.data.data.filename,
                    fileType: file.type,
                    filePreviewObj: fileUrl,
                    uploadedFileData: res.data.data,
                  }))
                } else if (fieldRef === "uboShareHoldingRegister_1") {
                  setShareholdingRegisterPreview_1(prevState => ({
                    ...prevState,
                    fileName: res.data.data.filename,
                    fileType: file.type,
                    filePreviewObj: fileUrl,
                    uploadedFileData: res.data.data,
                  }))
                } else if (fieldRef === "uboShareHoldingRegister_2") {
                  setShareholdingRegisterPreview_2(prevState => ({
                    ...prevState,
                    fileName: res.data.data.filename,
                    fileType: file.type,
                    filePreviewObj: fileUrl,
                    uploadedFileData: res.data.data,
                  }))
                } else if (fieldRef === "uboShareHoldingRegister_3") {
                  setShareholdingRegisterPreview_3(prevState => ({
                    ...prevState,
                    fileName: res.data.data.filename,
                    fileType: file.type,
                    filePreviewObj: fileUrl,
                    uploadedFileData: res.data.data,
                  }))
                } else if (fieldRef === "uboDocumentFile_1") {
                  setUboResidenceProofPreview_1(prevState => ({
                    ...prevState,
                    fileName: res.data.data.filename,
                    fileType: file.type,
                    filePreviewObj: fileUrl,
                    uploadedFileData: res.data.data,
                  }))
                } else if (fieldRef === "uboDocumentFile_2") {
                  setUboResidenceProofPreview_2(prevState => ({
                    ...prevState,
                    fileName: res.data.data.filename,
                    fileType: file.type,
                    filePreviewObj: fileUrl,
                    uploadedFileData: res.data.data,
                  }))
                } else if (fieldRef === "uboDocumentFile_3") {
                  setUboResidenceProofPreview_3(prevState => ({
                    ...prevState,
                    fileName: res.data.data.filename,
                    fileType: file.type,
                    filePreviewObj: fileUrl,
                    uploadedFileData: res.data.data,
                  }))
                } else if (fieldRef === "lrPassportFile_1") {
                  setLrPassportPreview_1(prevState => ({
                    ...prevState,
                    fileName: res.data.data.filename,
                    fileType: file.type,
                    filePreviewObj: fileUrl,
                    uploadedFileData: res.data.data,
                  }))
                } else if (fieldRef === "lrPassportFile_2") {
                  setLrPassportPreview_2(prevState => ({
                    ...prevState,
                    fileName: res.data.data.filename,
                    fileType: file.type,
                    filePreviewObj: fileUrl,
                    uploadedFileData: res.data.data,
                  }))
                } else if (fieldRef === "lrPassportFile_3") {
                  setLrPassportPreview_3(prevState => ({
                    ...prevState,
                    fileName: res.data.data.filename,
                    fileType: file.type,
                    filePreviewObj: fileUrl,
                    uploadedFileData: res.data.data,
                  }))
                } else if (fieldRef === "lrIdFile_1") {
                  setLrIdentityCardPreview_1(prevState => ({
                    ...prevState,
                    fileName: res.data.data.filename,
                    fileType: file.type,
                    filePreviewObj: fileUrl,
                    uploadedFileData: res.data.data,
                  }))
                } else if (fieldRef === "lrIdFile_2") {
                  setLrIdentityCardPreview_2(prevState => ({
                    ...prevState,
                    fileName: res.data.data.filename,
                    fileType: file.type,
                    filePreviewObj: fileUrl,
                    uploadedFileData: res.data.data,
                  }))
                } else if (fieldRef === "lrIdFile_3") {
                  setLrIdentityCardPreview_3(prevState => ({
                    ...prevState,
                    fileName: res.data.data.filename,
                    fileType: file.type,
                    filePreviewObj: fileUrl,
                    uploadedFileData: res.data.data,
                  }))
                } else if (fieldRef === "lrDocumentFile_1") {
                  setLrResidenceProofPreview_1(prevState => ({
                    ...prevState,
                    fileName: res.data.data.filename,
                    fileType: file.type,
                    filePreviewObj: fileUrl,
                    uploadedFileData: res.data.data,
                  }))
                } else if (fieldRef === "lrDocumentFile_2") {
                  setLrResidenceProofPreview_2(prevState => ({
                    ...prevState,
                    fileName: res.data.data.filename,
                    fileType: file.type,
                    filePreviewObj: fileUrl,
                    uploadedFileData: res.data.data,
                  }))
                } else if (fieldRef === "lrDocumentFile_3") {
                  setLrResidenceProofPreview_3(prevState => ({
                    ...prevState,
                    fileName: res.data.data.filename,
                    fileType: file.type,
                    filePreviewObj: fileUrl,
                    uploadedFileData: res.data.data,
                  }))
                }
              }

              reader.readAsDataURL(file) // Read the file as a data URL
            }
          }
        })
        .catch(error => {
          formik.setFieldError(
            fieldRef,
            "Something went wrong! Please try again."
          )
          console.error("Error:", error.message)
        })
    }
  }

  const formattedDate = date => {
    let dateN = new Date(date)
    return [
      dateN.getFullYear(),
      String(dateN.getMonth() + 1).padStart(2, "0"),
      String(dateN.getDate()).padStart(2, "0"),
    ].join("-")
  }

  const getCountryCodeObj = countrycode => {
    const existsCountryCode = countryList.find(
      obj => obj.countryCode === countrycode
    )
    console.log(
      existsCountryCode.countryCode,
      existsCountryCode.name,
      existsCountryCode.countryCode,
      "CC"
    )
    return {
      value: existsCountryCode.countryCode,
      label: `${existsCountryCode.name} (${existsCountryCode.countryCode})`,
    }
  }
  const getCountryObj = country => {
    return residenceCountry.find(obj => obj.value === country)
  }
  const getDocumentType = document => {
    return documentTypeList.find(obj => obj.value === document)
  }
  useEffect(() => {
    if (
      usersById.length > 0 &&
      usersById !== null &&
      userId !== null &&
      userId !== undefined
    ) {
      console.log("usersById", usersById[0])
      setLangID(usersById[0].lang_id)
      formik.setFieldValue("email", usersById[0]?.email)

      const companyObj = usersById[0]?.userdetail?.userlrdetail.find(item => {
        return item.detailtype === "company"
      })
      const poiObj = usersById[0]?.userdetail?.userlrdetail.find(item => {
        return item.detailtype === "pointofcontact"
      })
      const uboArr = usersById[0]?.userdetail?.userlrdetail.filter(item => {
        return item.detailtype === "ubo"
      })
      const lrArr = usersById[0]?.userdetail?.userlrdetail.filter(item => {
        return item.detailtype === "lr"
      })
      setUboCount(uboArr.length)
      setLegalRepCount(lrArr.length)
      formik.setFieldValue("uboSelect", {
        value: String(uboArr.length),
        label: String(uboArr.length),
      })
      formik.setFieldValue("legalSelect", {
        value: String(lrArr.length),
        label: String(lrArr.length),
      })

      // company section prefill
      formik.setFieldValue("companyName", companyObj?.companyname)
      formik.setFieldValue(
        "incorporationDate",
        formattedDate(companyObj?.companyincorporatedate)
      )
      formik.setFieldValue("taxIdentificationNumber", companyObj?.taxnumber)
      updateStateForFilePreview(
        setTradeRegisterPreview,
        "tradeRegister",
        companyObj?.document[0]?.fileName,
        companyObj?.document[0]?.documenturl,
        companyObj?.document[0]?.documentsubtype
      )
      updateStateForFilePreview(
        setAuthorizedSignatoriesPreview,
        "authorizedSignatories",
        companyObj?.document[1]?.fileName,
        companyObj?.document[1]?.documenturl,
        companyObj?.document[1]?.documentsubtype
      )
      updateStateForFilePreview(
        setArticlesOfAssociationPreview,
        "articlesOfAssociation",
        companyObj?.document[2]?.fileName,
        companyObj?.document[2]?.documenturl,
        companyObj?.document[2]?.documentsubtype
      )
      updateStateForFilePreview(
        setFinancialStatementsPreview,
        "financialStatements",
        companyObj?.document[3]?.fileName,
        companyObj?.document[3]?.documenturl,
        companyObj?.document[3]?.documentsubtype
      )
      updateStateForFilePreview(
        setFinancialStatements1Preview,
        "financialStatements1",
        companyObj?.document[4]?.fileName,
        companyObj?.document[4]?.documenturl,
        companyObj?.document[4]?.documentsubtype
      )

      // point of contact section
      formik.setFieldValue("contactFirstName", poiObj?.firstname)
      formik.setFieldValue("contactLastName", poiObj?.lastname)
      formik.setFieldValue("contactEmail", poiObj?.email)
      formik.setFieldValue(
        "contactCountrySelect",
        poiObj?.country !== null ? getCountryObj(poiObj?.country) : null
      )
      formik.setFieldValue(
        "contactCountryCodeSelect",
        poiObj?.countrycode !== null
          ? getCountryCodeObj(poiObj?.countrycode)
          : null
      )
      formik.setFieldValue("contactNumber", poiObj?.contact)

      // uboIdProofs.length = 0
      // lrIdProofs.length = 0

      // ubo details update
      for (let i = 1; i <= uboArr.length; i++) {
        formik.setFieldValue("uboId_" + i, uboArr[i - 1]?.id)
        formik.setFieldValue("uboFname_" + i, uboArr[i - 1]?.firstname)
        formik.setFieldValue("uboLname_" + i, uboArr[i - 1]?.lastname)
        formik.setFieldValue(
          "uboCountryCode_" + i,
          uboArr[i - 1]?.countrycode !== null
            ? getCountryCodeObj(uboArr[i - 1]?.countrycode)
            : null
        )
        formik.setFieldValue("uboPhn_" + i, uboArr[i - 1]?.contact)

        setUboIdProofs(prevItems => {
          // Create a new array with the updated item
          const updatedItems = [...prevItems]
          updatedItems[i - 1] =
            uboArr[i - 1]?.document[0]?.documentsubtype.toLowerCase() ===
              "identity card" ||
            uboArr[i - 1]?.document[0]?.documentsubtype.toLowerCase() ===
              "carta d'identità"
              ? "identitycard"
              : (uboArr[i - 1]?.document[0]?.documentsubtype).toLowerCase()

          return updatedItems
        })
        formik.setFieldValue(
          uboArr[i - 1]?.document[0]?.documentsubtype?.toLowerCase() ===
            "passport" ||
            uboArr[i - 1]?.document[0]?.documentsubtype?.toLowerCase() ===
              "passaporto"
            ? "uboPassportNum_" + i
            : "uboIdNum_" + i,
          uboArr[i - 1]?.document[0]?.documentnumber
        )
        formik.setFieldValue(
          uboArr[i - 1]?.document[0]?.documentsubtype.toLowerCase() ===
            "passport" ||
            uboArr[i - 1]?.document[0]?.documentsubtype.toLowerCase() ===
              "passaporto"
            ? "uboExpiryDatePassport_" + i
            : "uboExpiryDateIDProof_" + i,
          formattedDate(uboArr[i - 1]?.document[0]?.expirydate)
        )

        if (
          (uboArr[i - 1]?.document[0]?.documentsubtype).toLowerCase() ===
            "passport" ||
          (uboArr[i - 1]?.document[0]?.documentsubtype).toLowerCase() ===
            "passaporto"
        )
          updateStateForFilePreview(
            i === 1
              ? setUboPassportPreview_1
              : i === 2
              ? setUboPassportPreview_2
              : setUboPassportPreview_3,
            "uboPassportFile_" + i,
            uboArr[i - 1]?.document[0]?.fileName,
            uboArr[i - 1]?.document[0]?.documenturl,
            uboArr[i - 1]?.document[0]?.documentsubtype?.toLowerCase()
          )
        else
          updateStateForFilePreview(
            i === 1
              ? setUboIdentityCardPreview_1
              : i === 2
              ? setUboIdentityCardPreview_2
              : setUboIdentityCardPreview_3,
            "uboIdFile_" + i,
            uboArr[i - 1]?.document[0]?.fileName,
            uboArr[i - 1]?.document[0]?.documenturl,
            uboArr[i - 1]?.document[0]?.documentsubtype?.toLowerCase()
          )

        formik.setFieldValue(
          "uboZipcode_" + i,
          uboArr[i - 1]?.document[1]?.zipcode
        )
        formik.setFieldValue(
          "uboCountry_" + i,
          getCountryObj(uboArr[i - 1]?.document[1]?.country)
        )
        formik.setFieldValue(
          "uboProvince_" + i,
          uboArr[i - 1]?.document[1]?.provience
        )
        formik.setFieldValue(
          "uboRegion_" + i,
          uboArr[i - 1]?.document[1]?.region
        )
        formik.setFieldValue("uboCity_" + i, uboArr[i - 1]?.document[1]?.city)
        formik.setFieldValue(
          "uboHouseNo_" + i,
          uboArr[i - 1]?.document[1]?.housenumber
        )
        formik.setFieldValue(
          "uboStreetArea_" + i,
          uboArr[i - 1]?.document[1]?.street
        )
        formik.setFieldValue(
          "uboDocumentType_" + i,
          getDocumentType(uboArr[i - 1]?.document[1]?.documentsubtype)
        )
        formik.setFieldValue(
          "uboDocumentDate_" + i,
          formattedDate(uboArr[i - 1]?.document[1]?.expirydate)
        )
        updateStateForFilePreview(
          i === 1
            ? setUboResidenceProofPreview_1
            : i === 2
            ? setUboResidenceProofPreview_2
            : setUboResidenceProofPreview_3,
          "uboDocumentFile_" + i,
          uboArr[i - 1]?.document[1]?.fileName,
          uboArr[i - 1]?.document[1]?.documenturl,
          uboArr[i - 1]?.document[1]?.documentsubtype?.toLowerCase()
        )

        updateStateForFilePreview(
          i === 1
            ? setShareholdingRegisterPreview_1
            : i === 2
            ? setShareholdingRegisterPreview_2
            : setShareholdingRegisterPreview_3,
          "uboShareHoldingRegister_" + i,
          uboArr[i - 1]?.document[2]?.fileName,
          uboArr[i - 1]?.document[2]?.documenturl,
          uboArr[i - 1]?.document[2]?.documentsubtype?.toLowerCase()
        )
      }

      // LR details update
      for (let i = 1; i <= lrArr.length; i++) {
        formik.setFieldValue("lrId_" + i, lrArr[i - 1]?.id)
        formik.setFieldValue("lrFname_" + i, lrArr[i - 1]?.firstname)
        formik.setFieldValue("lrLname_" + i, lrArr[i - 1]?.lastname)
        formik.setFieldValue(
          "lrCountryCode_" + i,
          lrArr[i - 1]?.countrycode !== null
            ? getCountryCodeObj(lrArr[i - 1]?.countrycode)
            : null
        )
        formik.setFieldValue("lrPhn_" + i, lrArr[i - 1]?.contact)

        setLrIdProofs(prevItems => {
          // Create a new array with the updated item
          const updatedItems = [...prevItems]
          updatedItems[i - 1] =
            (lrArr[i - 1]?.document[0]?.documentsubtype).toLowerCase() ===
              "identity card" ||
            (lrArr[i - 1]?.document[0]?.documentsubtype).toLowerCase() ===
              "carta d'identità"
              ? "identitycard"
              : (lrArr[i - 1]?.document[0]?.documentsubtype).toLowerCase()
          console.log(updatedItems, "LR doc render!!!")
          return updatedItems
        })
        formik.setFieldValue(
          (lrArr[i - 1]?.document[0]?.documentsubtype).toLowerCase() ===
            "passport" ||
            (lrArr[i - 1]?.document[0]?.documentsubtype).toLowerCase() ===
              "passaporto"
            ? "lrPassportNum_" + i
            : "lrIdNum_" + i,
          lrArr[i - 1]?.document[0]?.documentnumber
        )
        formik.setFieldValue(
          (lrArr[i - 1]?.document[0]?.documentsubtype).toLowerCase() ===
            "passport" ||
            (lrArr[i - 1]?.document[0]?.documentsubtype).toLowerCase() ===
              "passaporto"
            ? "lrExpiryDatePassport_" + i
            : "lrExpiryDateIDProof_" + i,
          formattedDate(lrArr[i - 1]?.document[0]?.expirydate)
        )
        if (
          (lrArr[i - 1]?.document[0]?.documentsubtype).toLowerCase() ===
            "passport" ||
          (lrArr[i - 1]?.document[0]?.documentsubtype).toLowerCase() ===
            "passaporto"
        )
          updateStateForFilePreview(
            i === 1
              ? setLrPassportPreview_1
              : i === 2
              ? setLrPassportPreview_2
              : setLrPassportPreview_3,
            "lrPassportFile_" + i,
            lrArr[i - 1]?.document[0]?.fileName,
            lrArr[i - 1]?.document[0]?.documenturl,
            lrArr[i - 1]?.document[0]?.documentsubtype?.toLowerCase()
          )
        else
          updateStateForFilePreview(
            i === 1
              ? setLrIdentityCardPreview_1
              : i === 2
              ? setLrIdentityCardPreview_2
              : setLrIdentityCardPreview_3,
            "lrIdFile_" + i,
            lrArr[i - 1]?.document[0]?.fileName,
            lrArr[i - 1]?.document[0]?.documenturl,
            lrArr[i - 1]?.document[0]?.documentsubtype?.toLowerCase()
          )

        formik.setFieldValue(
          "lrZipcode_" + i,
          lrArr[i - 1]?.document[1]?.zipcode
        )
        formik.setFieldValue(
          "lrCountry_" + i,
          getCountryObj(lrArr[i - 1]?.document[1]?.country)
        )
        formik.setFieldValue(
          "lrProvince_" + i,
          lrArr[i - 1]?.document[1]?.provience
        )
        formik.setFieldValue("lrRegion_" + i, lrArr[i - 1]?.document[1]?.region)
        formik.setFieldValue("lrCity_" + i, lrArr[i - 1]?.document[1]?.city)
        formik.setFieldValue(
          "lrHouseNo_" + i,
          lrArr[i - 1]?.document[1]?.housenumber
        )
        formik.setFieldValue(
          "lrStreetArea_" + i,
          lrArr[i - 1]?.document[1]?.street
        )
        formik.setFieldValue(
          "lrDocumentType_" + i,
          getDocumentType(lrArr[i - 1]?.document[1]?.documentsubtype)
        )
        formik.setFieldValue(
          "lrDocumentDate_" + i,
          formattedDate(lrArr[i - 1]?.document[1]?.expirydate)
        )
        updateStateForFilePreview(
          i === 1
            ? setLrResidenceProofPreview_1
            : i === 2
            ? setLrResidenceProofPreview_2
            : setLrResidenceProofPreview_3,
          "lrDocumentFile_" + i,
          lrArr[i - 1]?.document[1]?.fileName,
          lrArr[i - 1]?.document[1]?.documenturl,
          lrArr[i - 1]?.document[1]?.documentsubtype?.toLowerCase()
        )
      }
    }
  }, [usersById])

  const updateStateForFilePreview = (
    setStateFn,
    formikRef,
    fileName,
    fileUrl,
    documentSubType
  ) => {
    const urlParts = fileUrl.split(".")
    const ext = urlParts[urlParts.length - 1]
    const mimeType = ext === "pdf" ? "application/pdf" : "image/" + ext
    setStateFn(prevState => ({
      ...prevState,
      fileName: fileName,
      fileType: mimeType,
      filePreviewObj: fileUrl,
    }))
    formik.setFieldValue(formikRef, fileUrl)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Add Legal Representative</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          {isEdit ? (
            <Breadcrumbs
              title="Legal Representative"
              breadcrumbItem="Edit Legal Representative"
            />
          ) : (
            <Breadcrumbs
              title="Legal Representative"
              breadcrumbItem="Add Legal Representative"
            />
          )}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Form onSubmit={formik.handleSubmit}>
                    <FormGroup className="mb-4">
                      <div className="row ">
                        <Label
                          htmlFor="email"
                          className="col-form-label col-lg-8"
                        >
                          Email
                        </Label>
                        <Col lg="8">
                          <Input
                            id="email"
                            name="email"
                            type="text"
                            placeholder="Enter Email..."
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={`${
                              formik.touched.email && formik.errors.email
                                ? "is-invalid"
                                : ""
                            }`}
                            invalid={
                              formik.touched.email &&
                              Boolean(formik.errors.email)
                            }
                            disabled={isEdit}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <FormFeedback>{formik.errors.email}</FormFeedback>
                          ) : null}
                        </Col>
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-4">
                      <div className="row ">
                        <Label
                          htmlFor="companyName"
                          className="col-form-label col-lg-8"
                        >
                          Company Name
                        </Label>
                        <Col lg="8">
                          <Input
                            id="companyName"
                            name="companyName"
                            type="text"
                            placeholder="Company name..."
                            value={formik.values.companyName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={`${
                              formik.touched.companyName &&
                              formik.errors.companyName
                                ? "is-invalid"
                                : ""
                            }`}
                            invalid={
                              formik.touched.companyName &&
                              Boolean(formik.errors.companyName)
                            }
                          />
                          {formik.touched.companyName &&
                          formik.errors.companyName ? (
                            <FormFeedback>
                              {formik.errors.companyName}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-4">
                      <div className="row ">
                        <Label
                          htmlFor="incorporationDate"
                          className="col-form-label col-lg-8"
                        >
                          Company&apos;s incorporation date
                        </Label>
                        <Col lg="8">
                          <Input
                            id="incorporationDate"
                            name="incorporationDate"
                            type="date"
                            max={getTodayDate()}
                            value={formik.values.incorporationDate}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.incorporationDate &&
                              formik.errors.incorporationDate
                                ? true
                                : false
                            }
                            className={`${
                              formik.touched.incorporationDate &&
                              formik.errors.incorporationDate
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          {formik.touched.incorporationDate &&
                          formik.errors.incorporationDate ? (
                            <FormFeedback>
                              {formik.errors.incorporationDate}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-4">
                      <div className="row ">
                        <Label
                          htmlFor="taxIdentificationNumber"
                          className="col-form-label col-lg-8"
                        >
                          Tax Identification Number
                        </Label>
                        <Col lg="8">
                          <Input
                            id="taxIdentificationNumber"
                            name="taxIdentificationNumber"
                            type="text"
                            placeholder="Enter tax identification number..."
                            value={formik.values.taxIdentificationNumber}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={`${
                              formik.touched.taxIdentificationNumber &&
                              formik.errors.taxIdentificationNumber
                                ? "is-invalid"
                                : ""
                            }`}
                            invalid={
                              formik.touched.taxIdentificationNumber &&
                              Boolean(formik.errors.taxIdentificationNumber)
                            }
                          />
                          {formik.touched.taxIdentificationNumber &&
                          formik.errors.taxIdentificationNumber ? (
                            <FormFeedback>
                              {formik.errors.taxIdentificationNumber}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-4">
                      <div className="row ">
                        <Label
                          htmlFor="tradeRegister"
                          className="col-form-label col-lg-8"
                        >
                          Trade Register
                        </Label>
                        <Col lg="8">
                          <Input
                            id="tradeRegister"
                            name="tradeRegister"
                            type="file"
                            accept="image/*,application/pdf"
                            onChange={e => handleUpload(e, "tradeRegister")}
                            onBlur={formik.handleBlur}
                            className={
                              formik.touched.tradeRegister &&
                              formik.errors.tradeRegister
                                ? "is-invalid"
                                : ""
                            }
                          />
                          {renderPreview(
                            tradeRegisterPreview.filePreviewObj,
                            tradeRegisterPreview.fileType,
                            tradeRegisterPreview.fileName
                          )}
                          {formik.touched.tradeRegister &&
                          formik.errors.tradeRegister ? (
                            <FormFeedback>
                              {formik.errors.tradeRegister}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-4">
                      <div className="row ">
                        <Label
                          htmlFor="authorizedSignatories"
                          className="col-form-label col-lg-8"
                        >
                          Authorized signatories of the company
                        </Label>
                        <Col lg="8">
                          <Input
                            id="authorizedSignatories"
                            name="authorizedSignatories"
                            type="file"
                            accept="image/*,application/pdf"
                            onChange={e =>
                              handleUpload(e, "authorizedSignatories")
                            }
                            onBlur={formik.handleBlur}
                            className={
                              formik.touched.authorizedSignatories &&
                              formik.errors.authorizedSignatories
                                ? "is-invalid"
                                : ""
                            }
                          />
                          {renderPreview(
                            authorizedSignatoriesPreview.filePreviewObj,
                            authorizedSignatoriesPreview.fileType,
                            authorizedSignatoriesPreview.fileName
                          )}
                          {formik.touched.authorizedSignatories &&
                          formik.errors.authorizedSignatories ? (
                            <FormFeedback>
                              {formik.errors.authorizedSignatories}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-4">
                      <div className="row ">
                        <Label
                          htmlFor="articlesOfAssociation"
                          className="col-form-label col-lg-8"
                        >
                          Articles of Association
                        </Label>
                        <Col lg="8">
                          <Input
                            id="articlesOfAssociation"
                            name="articlesOfAssociation"
                            type="file"
                            accept="image/*,application/pdf"
                            onChange={e =>
                              handleUpload(e, "articlesOfAssociation")
                            }
                            onBlur={formik.handleBlur}
                            className={
                              formik.touched.articlesOfAssociation &&
                              formik.errors.articlesOfAssociation
                                ? "is-invalid"
                                : ""
                            }
                          />
                          {renderPreview(
                            articlesOfAssociationPreview.filePreviewObj,
                            articlesOfAssociationPreview.fileType,
                            articlesOfAssociationPreview.fileName
                          )}
                          {formik.touched.articlesOfAssociation &&
                          formik.errors.articlesOfAssociation ? (
                            <FormFeedback>
                              {formik.errors.articlesOfAssociation}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-4">
                      <div className="row ">
                        <Label
                          htmlFor="financialStatements"
                          className="col-form-label col-lg-8"
                        >
                          Financial statements for the year{" "}
                          {getCurrentFinancialYear()}
                        </Label>
                        <Col lg="8">
                          <Input
                            id="financialStatements"
                            name="financialStatements"
                            type="file"
                            accept="image/*,application/pdf"
                            onChange={e =>
                              handleUpload(e, "financialStatements")
                            }
                            onBlur={formik.handleBlur}
                            className={
                              formik.touched.financialStatements &&
                              formik.errors.financialStatements
                                ? "is-invalid"
                                : ""
                            }
                          />
                          {renderPreview(
                            financialStatementsPreview.filePreviewObj,
                            financialStatementsPreview.fileType,
                            financialStatementsPreview.fileName
                          )}
                          {formik.touched.financialStatements &&
                          formik.errors.financialStatements ? (
                            <FormFeedback>
                              {formik.errors.financialStatements}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-4">
                      <div className="row ">
                        <Label
                          htmlFor="financialStatements1"
                          className="col-form-label col-lg-8"
                        >
                          Financial statements for the year{" "}
                          {getLastFinancialYear()}
                        </Label>
                        <Col lg="8">
                          <Input
                            id="financialStatements1"
                            name="financialStatements1"
                            type="file"
                            accept="image/*,application/pdf"
                            onChange={e =>
                              handleUpload(e, "financialStatements1")
                            }
                            onBlur={formik.handleBlur}
                            className={
                              formik.touched.financialStatements1 &&
                              formik.errors.financialStatements1
                                ? "is-invalid"
                                : ""
                            }
                          />
                          {renderPreview(
                            financialStatements1Preview.filePreviewObj,
                            financialStatements1Preview.fileType,
                            financialStatements1Preview.fileName
                          )}
                          {formik.touched.financialStatements1 &&
                          formik.errors.financialStatements1 ? (
                            <FormFeedback>
                              {formik.errors.financialStatements1}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </div>
                    </FormGroup>
                    <h2 className="mb-4">UBO&apos; s Details</h2>
                    <FormGroup className="mb-4">
                      <div className="row col-lg-6">
                        <Label className="col-form-label col-lg-8">
                          Number of UBO&apos;s
                        </Label>
                        <Col lg="8">
                          <Select
                            isMulti={false}
                            id="uboSelect"
                            name="uboSelect"
                            options={[
                              { value: "1", label: "1" },
                              { value: "2", label: "2" },
                              { value: "3", label: "3" },
                            ]}
                            // onChange={handleUboCountChange}
                            value={formik.values.uboSelect}
                            onChange={option => {
                              formik.setFieldValue("uboSelect", option)
                              setUboCount(option.value)
                            }}
                            onBlur={() =>
                              formik.setFieldTouched("uboSelect", true)
                            }
                            invalid={
                              formik.touched.uboSelect &&
                              formik.errors.uboSelect
                                ? true
                                : false
                            }
                            className={
                              formik.touched.uboSelect &&
                              formik.errors.uboSelect
                                ? "is-invalid"
                                : ""
                            }
                          />
                          {formik.touched.uboSelect &&
                          formik.errors.uboSelect ? (
                            <FormFeedback>
                              {formik.errors.uboSelect}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </div>
                    </FormGroup>
                    {renderUboSections()}
                    <h2>Legal Representative Details</h2>
                    <FormGroup className="mb-4">
                      <div className="row col-lg-6">
                        <Label className="col-form-label col-lg-8">
                          Number of Legal Representatives
                        </Label>
                        <Col lg="8">
                          <Select
                            isMulti={false}
                            id="legalSelect"
                            name="legalSelect"
                            options={[
                              { value: "1", label: "1" },
                              { value: "2", label: "2" },
                              { value: "3", label: "3" },
                            ]}
                            // onChange={handleLegalRepCountChange}
                            value={formik.values.legalSelect}
                            onChange={option => {
                              formik.setFieldValue("legalSelect", option)
                              setLegalRepCount(option.value)
                            }}
                            onBlur={() =>
                              formik.setFieldTouched("legalSelect", true)
                            }
                            invalid={
                              formik.touched.legalSelect &&
                              formik.errors.legalSelect
                                ? true
                                : false
                            }
                            className={
                              formik.touched.legalSelect &&
                              formik.errors.legalSelect
                                ? "is-invalid"
                                : ""
                            }
                          />
                          {formik.touched.legalSelect &&
                          formik.errors.legalSelect ? (
                            <FormFeedback>
                              {formik.errors.legalSelect}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </div>
                    </FormGroup>
                    {renderLegalRepSections()}
                    <h2>Point of Contact&apos;s Details</h2>
                    <FormGroup className="mb-4">
                      <div className="row ">
                        <div className="col-lg-4">
                          <Label
                            htmlFor={"contactFirstName"}
                            className="col-form-label col-lg-12"
                          >
                            First Name
                          </Label>
                          <Input
                            id={"contactFirstName"}
                            name={"contactFirstName"}
                            type="text"
                            placeholder="Enter First Name..."
                            value={formik.values.contactFirstName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={`${
                              formik.touched.contactFirstName &&
                              formik.errors.contactFirstName
                                ? "is-invalid"
                                : ""
                            }`}
                            invalid={
                              formik.touched.contactFirstName &&
                              Boolean(formik.errors.contactFirstName)
                            }
                            // disabled={isEdit}
                          />
                          {formik.touched.contactFirstName &&
                          formik.errors.contactFirstName ? (
                            <FormFeedback>
                              {formik.errors.contactFirstName}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="col-lg-4">
                          <Label
                            htmlFor={"contactLastName"}
                            className="col-form-label col-lg-12"
                          >
                            Last Name
                          </Label>
                          <Input
                            id={"contactLastName"}
                            name={"contactLastName"}
                            type="text"
                            placeholder="Enter Last Name..."
                            value={formik.values.contactLastName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={`${
                              formik.touched.contactLastName &&
                              formik.errors.contactLastName
                                ? "is-invalid"
                                : ""
                            }`}
                            invalid={
                              formik.touched.contactLastName &&
                              Boolean(formik.errors.contactLastName)
                            }
                            // disabled={isEdit}
                          />
                          {formik.touched.contactLastName &&
                          formik.errors.contactLastName ? (
                            <FormFeedback>
                              {formik.errors.contactLastName}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-4">
                      <div className="row">
                        <Label
                          htmlFor="contactEmail"
                          className="col-form-label"
                        >
                          Email
                        </Label>
                        <Col lg="8">
                          <Input
                            id="contactEmail"
                            name="contactEmail"
                            type="text"
                            placeholder="Enter Email..."
                            value={formik.values.contactEmail}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={`${
                              formik.touched.contactEmail &&
                              formik.errors.contactEmail
                                ? "is-invalid"
                                : ""
                            }`}
                            invalid={
                              formik.touched.contactEmail &&
                              Boolean(formik.errors.contactEmail)
                            }
                            // disabled={isEdit}
                          />
                          {formik.touched.contactEmail &&
                          formik.errors.contactEmail ? (
                            <FormFeedback>
                              {formik.errors.contactEmail}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-4">
                      <div className="row ">
                        <Label
                          className="col-form-label"
                          htmlFor={"contactCountrySelect"}
                        >
                          Country
                        </Label>
                        <Col lg="8">
                          <Select
                            isMulti={false}
                            id={"contactCountrySelect"}
                            name={"contactCountrySelect"}
                            options={residenceCountry}
                            value={formik.values.contactCountrySelect}
                            onChange={option =>
                              formik.setFieldValue(
                                "contactCountrySelect",
                                option
                              )
                            }
                            onBlur={() =>
                              formik.setFieldTouched(
                                "contactCountrySelect",
                                true
                              )
                            }
                            invalid={
                              formik.touched.contactCountrySelect &&
                              formik.errors.contactCountrySelect
                                ? true
                                : false
                            }
                            className={
                              formik.touched.contactCountrySelect &&
                              formik.errors.contactCountrySelect
                                ? "is-invalid"
                                : ""
                            }
                          />
                          {formik.touched.contactCountrySelect &&
                          formik.errors.contactCountrySelect ? (
                            <FormFeedback>
                              {formik.errors.contactCountrySelect}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-4">
                      <div className="row ">
                        <div className="col-lg-3">
                          <Label
                            htmlFor={"contactCountryCodeSelect"}
                            className="col-form-label"
                          >
                            Country Code
                          </Label>
                          <Col>
                            <Select
                              isMulti={false}
                              id={"contactCountryCodeSelect"}
                              name={"contactCountryCodeSelect"}
                              options={mobileNumberOptions}
                              placeholder="Select country code"
                              value={formik.values.contactCountryCodeSelect}
                              onChange={option =>
                                formik.setFieldValue(
                                  "contactCountryCodeSelect",
                                  option
                                )
                              }
                              onBlur={() =>
                                formik.setFieldTouched(
                                  "contactCountryCodeSelect",
                                  true
                                )
                              }
                              className={
                                formik.touched.contactCountryCodeSelect &&
                                formik.errors.contactCountryCodeSelect
                                  ? "is-invalid"
                                  : ""
                              }
                              // invalid={formik.touched.contactCountryCodeSelect && Boolean(formik.errors.contactCountryCodeSelect)}
                            />
                            {formik.touched.contactCountryCodeSelect &&
                            formik.errors.contactCountryCodeSelect ? (
                              <FormFeedback>
                                {formik.errors.contactCountryCodeSelect}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </div>
                        <div className="col-lg-5">
                          <Label
                            htmlFor={"contactNumber"}
                            className="col-form-label"
                          >
                            Mobile Number
                          </Label>
                          <Input
                            id={"contactNumber"}
                            name={"contactNumber"}
                            type="text"
                            placeholder="Enter Mobile Number..."
                            value={formik.values.contactNumber}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={`${
                              formik.touched.contactNumber &&
                              formik.errors.contactNumber
                                ? "is-invalid"
                                : ""
                            }`}
                            invalid={
                              formik.touched.contactNumber &&
                              Boolean(formik.errors.contactNumber)
                            }
                          />
                          {formik.touched.contactNumber &&
                          formik.errors.contactNumber ? (
                            <FormFeedback>
                              {formik.errors.contactNumber}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                    </FormGroup>
                    <Row>
                      <Col xs="12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-center">
                          <Button
                            color="primary"
                            type="submit"
                            className="btn-rounded p-4 pb-2 pt-2"
                          >
                            Save
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddLr
